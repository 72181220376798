import '../../css/custom.css';
import React, {useEffect, useState} from "react";
import EventRow from "../component/EventRow";
import TitleRow from "../component/TitleRow";
import Splitter from "../component/Splitter";
import TaskCard from "../component/TaskCard";
import TaskListByGroupNameAndGroupType from "./TaskListByGroupNameAndGroupType";
import OperationTasks from "./OperationTasks";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import {useSnackbar} from "notistack";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl} from "../schema/Environment";

function TaskListForGroupName(props){

    console.log("TaskListForGroupName");
    const {enqueueSnackbar} = useSnackbar()
    const {loaded, error, data} = AxiosGet(apiUrl + "/neighbor/task/findGroupTypesByGroupName", "GET", {"taskGroupType": props.groupType}, enqueueSnackbar);


        if (error) {
            return (
                <div className="content-wrappers">
                    <EventRow time="" title="" error="Данные временно недоступны"/>
                </div>
            )
        } else if (loaded === false) {
            return (
                <div>
                        <div>
                            <Splitter/>
                            <div className="content-wrappers">
                                <TitleRow title=""/>
                                <TaskListByGroupNameAndGroupType preload="true"/>
                            </div>
                        </div>
                </div>
            )
        } else {
            return (
                <div>
                    {data.map(item => (
                        <div key={item.taskGroupName + '_cont'}>
                            <Splitter/>
                            <div className="content-wrappers">
                                <TitleRow key={item.taskGroupName + '_title'}  title={item.name}/>
                                <TaskListByGroupNameAndGroupType key={item.taskGroupName} groupName={item.taskGroupName}  groupType={props.groupType}/>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

}


export default TaskListForGroupName;
