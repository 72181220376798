import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const NotFoundRedirect = () => {
    const history = useHistory();

    useEffect(() => {
        history.push('/main');
    }, [history]);

    return null;
};

export default NotFoundRedirect;
