import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle
} from '@mui/material';

import {styled} from "@mui/system";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '&.MuiDialog-root': {
        zIndex: 100,
        // Другие пользовательские стили для бумажного компонента
    },
    // Другие пользовательские стили, если необходимо
}));


function ModalWithContent ({content, isOpen, onClose }) {
    return (
        <StyledDialog open={isOpen} onClose={onClose} PaperProps={{
            sx: {
                maxWidth: 'unset',
                width: '98%',
                height: '98%'
            }
        }}>
            <DialogTitle></DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            {/*<DialogActions>*/}
            {/*    <Button onClick={onClose}>Close</Button>*/}
            {/*</DialogActions>*/}
        </StyledDialog>
    );
}

export default ModalWithContent;
