import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {styled} from "@mui/material/styles";
import {InputLabel} from "@mui/material";

const MinimalSelect = styled(Select)(({ theme }) => ({
    '&.MuiInputBase-root': {
        fontSize: "1.5rem",
        fontWeight: "500",
        color: "unset",
        // color: "white",
        '.MuiSelect-select': {
            padding: "unset !important",
            textAlign: "center"
        },
        '.MuiSvgIcon-root': {
            display: "none"
        },
        '.css-1d3z3hw-MuiOutlinedInput-notchedOutline':{
            border: "unset"
        },
        '.Mui-disabled':{
            "-webkit-text-fill-color": "unset"
        }
    },
}));

const SelectMinPadding = styled(Select)(({ theme }) => ({
    backgroundColor: "white",
    borderRadius: "10rem",
    height: "10rem",
    fontSize: "4.5rem",
    '&.MuiInputBase-root': {
        fontSize: "4.5rem",
        fontWeight: "500",
        color: "unset",
        // color: "white",
        '.MuiSelect-select': {
            padding: "unset !important",
            textAlign: "center"
        },
        '.Mui-disabled':{
            "-webkit-text-fill-color": "unset"
        }
    },
}));

export default function SelectValue(props) {
    const [value, setValue] = React.useState(props.selectedValue);

    const handleChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setValue(event.target.value);
        if(props.handleChange !== undefined){
            props.handleChange(event.target.value, props.selectName);
        }
    };

    return (
        <Box>
            <FormControl fullWidth disabled={props.disabled}>
                <MinimalSelect
                    value={value}
                    onChange={handleChange}
                    autoWidth
                    // sx={{ color: props.color + ' !important'}}
                >
                    {props.setValues.map((item, index) => (
                        <MenuItem value={item.key} key={index} sx={{ fontSize: "1.5rem" }}>
                            {item.value}
                        </MenuItem>
                    ))}
                </MinimalSelect>
            </FormControl>
        </Box>
    );
}


export function SelectValueFullWidth({title, valuesSet, selectedValue, onChangeFunction, disabled}) {
    let value = selectedValue === null ? '' : selectedValue;
    const handleChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if(onChangeFunction){
            onChangeFunction(event.target.value);
        }
    };

    const renderSelectValue = () => {
        const selectedItem = valuesSet.find(item => item.key === selectedValue);
        return selectedItem ? selectedItem.value : '';
    };



    return (
            <FormControl fullWidth disabled={disabled}>
                <InputLabel>{title}</InputLabel>
                <SelectMinPadding
                    value={value}
                    onChange={(event) => handleChange(event)}
                    // autoWidth
                    renderValue={renderSelectValue}
                    sx={{ padding: '1rem'}}
                >
                    {valuesSet.map((item, index) => (
                        <MenuItem value={item.key} key={index} sx={{ fontSize: "1.5rem" }}>
                            {item.value}
                        </MenuItem>
                    ))}
                </SelectMinPadding>
            </FormControl>
    );
}
