import {notifyModalStateParam} from "./Constants";
import {setNotifyModalStateWithParam} from "./NotifyModalStateService";

export function InitResponseInitialState(){
    return {
        isLoaded: false,
        hasError: false,
        text: null,
        body: null
    };
}

export function FetchQuery(url) {

    return fetch(url)
        .then((response) => {
            if(response.status !== 200)
                throw new Error(response.status);
            else
                return response.json()
        })
        .then(
            (result) => {
                let fetchResult;
                if (result.appResponseType === 'EXPECTED_ERROR' || result.appResponseType === 'UNEXPECTED_ERROR') {
                    fetchResult = setFetchResponse (true, result.text, null);
                }
                if (result.appResponseType === 'OK') {
                    fetchResult = setFetchResponse (false, result.text, result.body);
                }
                return fetchResult;
            })
        .catch(error => {
            return setFetchResponse (true, "Сервис временно недоступен", null);
        });
}

export function FetchFormDataFileSendQuery(url, file) {

    const formData = new FormData();

    formData.append('file', file);

    return fetch(
        url, {method: 'POST', body: formData,})
        .then((response) => {
            if(response.status !== 200)
                throw new Error(response.status);
            else
                return response.json()
        })
        .then((response) => {
            return  setFetchResponse(false, 'Аватар успешно изменен', response.body);
        })
        .catch((error) => {
            return setFetchResponse (true, 'Что-то пошло не так, попробуйте позже', null);
        });
}

function setFetchResponse(hasError, text, body) {
    let fetchResponse = InitResponseInitialState();
    fetchResponse.isLoaded = true;
    fetchResponse.hasError = hasError;
    fetchResponse.text = text;
    fetchResponse.body = body;
    return fetchResponse;
}

class FetchService{


}

export default FetchService;
