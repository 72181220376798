import '../../css/custom.css';
import React from "react";

function DailyPresentItem(props) {
    return (
        <div className="icon-description">
            <div className="icon-wrapper daily-present-icon-wrapper centeredWrapper">
                <img src={props.icon} className="icon-for-daily-present"/>
            </div>
            <div className="align-center width-inherit">
                <div className="icon-description-text">{props.description}
                </div>
            </div>
        </div>
    );
}


export default DailyPresentItem;
