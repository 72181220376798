export function InitNotifyModalStateParam(){
    return {
        class: 'cssClass',
        text: 'text'
    }
}

export function setNotifyModalStateWithParam(className, text) {
    let notifyModalState = InitNotifyModalStateParam();
    notifyModalState.class = className;
    notifyModalState.text = text;
    return notifyModalState;
}

class NotifyModalStateService{

}

export default NotifyModalStateService;
