import '../../css/custom.css';
import Splitter from "../component/Splitter";
import TitleRow from "../component/TitleRow";
import UsefulButton from "../component/UsefulButton";

import logo_tg from '../../img/tg_white.png';
import logo_sport from '../../img/sport_icon.png';
import logo_legend from '../../img/rating_white.png';
import Anons from "../class/Anons";
import NearEventList from "../class/NearEventList";
import TeamCoinBalance from "../class/TeamCoinBalance";
import {useHistory} from "react-router-dom";


function MainSheet() {
    const history = useHistory();

    const handleClick = () => {
        history.push('/bets/list');
    };
    return (
        <div id="main-content-wrapper">
            <Splitter/>
            <div className="content-wrappers">
                <TitleRow title="Полезности"/>
                <div className="inline-flex" >
                    <a href="https://t.me/StupinoNeverSleepBot" target="_blank">
                        <UsefulButton title="TG Bot" image={logo_tg}/>
                    </a>
                    <a onClick={handleClick}>
                        <UsefulButton title="Ставки" image={logo_sport}/>
                    </a>
                    {/*<UsefulButton title="Legends" image={logo_legend}/>*/}
                </div>
            </div>

            <Splitter/>
            <Anons/>
            <Splitter/>
            <NearEventList/>

            <Splitter/>
            <TeamCoinBalance/>
        </div>
    );
}


export default MainSheet;
