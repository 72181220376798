import '../../css/custom.css';
import React, {useContext, useEffect, useState} from "react";
import EventRow from "../component/EventRow";
import TitleRow from "../component/TitleRow";
import Splitter from "../component/Splitter";
import TaskCard from "../component/TaskCard";
import {AuthModalContext, TokenContext} from "../Contexts";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import {apiUrl} from "../schema/Environment";
import {Box, useTheme} from "@mui/system";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserLock} from "@fortawesome/free-solid-svg-icons";
import {Typography} from "@mui/material";
import {AxiosGet} from "../axios/AxiosGet";
import {useSnackbar} from "notistack";
import WaitImage from "../../img/wait.jpg";

function OperationTasks({taskType}) {

    const {enqueueSnackbar} = useSnackbar()
    const theme = useTheme();
    const [currentToken, setToken] = useContext(TokenContext);
    const [modalActive, setModalActive] = useContext(AuthModalContext);
    const [fetchResult, setFetchResult] = useState(InitResponseInitialState());

    const {loaded, error, data} = AxiosGet(apiUrl + "/neighbor/task/" + taskType, "GET", null, enqueueSnackbar, currentToken);


        if (error) {
            return (
                <div id="main-content-wrapper">
                    <Splitter/>
                    <div className="content-wrappers">
                        <EventRow time="" title="" error="Данные временно недоступны"/>
                    </div>
                </div>
            )
        } else if (loaded === false) {
            return (
                <div className="product-list-item-wrapper" >
                    <TaskCard preload={true} appendClass="large"/>
                </div>
            )
        } else {
            console.log("OperationTasks");
            if(data.length > 0)
                return (
                    <div className="product-list-item-wrapper">
                        {data.map((item,index) => (
                            <TaskCard
                                key={index}
                                id={item.task.id}
                                title={item.task.title}
                                description={item.task.description}
                                conditions={item.task.conditions}
                                cost={item.task.cost}
                                withTargetUser={item.task.withTargetUser}
                                taskGroupName={item.task.taskGroupName}
                                taskGroupType={item.task.taskGroupType}
                                duration={item.task.duration}
                                mediaFile={item.task.mediaFile}
                                buyCount={item.task.buyCount}
                                ownerUser={item.ownerUser}
                                aimUser={item.targetUser}
                                taskUseStatus={item.taskUseStatus}
                                duelResult={item.duelResult}
                                useTime={item.useTime}
                                browse={true}
                                appendClass="large"
                            />
                        ))}
                    </div>
                );
            else {
                let taskTaskParams
                if(taskType === 'bought')
                    taskTaskParams = emptyTaskBought;
                if(taskType === 'assigned')
                    taskTaskParams = emptyTaskAssigned;
                if(taskType === 'bonus')
                    taskTaskParams = emptyTaskBonus;
                return (
                    <div className="product-list-item-wrapper">
                        <TaskCard title={taskTaskParams.title}
                                  description={taskTaskParams.description}
                                  mediaFile={taskTaskParams.mediaFile}
                                  cost={taskTaskParams.cost}
                                  completed={taskTaskParams.completed}
                                  browse={taskTaskParams.browse}
                                  taskGroupType={"INFO"}
                                  appendClass="large"/>
                    </div>
                );
            }
        }
}

const emptyTaskBought = {
    title: 'Купленное задание',
    description: "Похоже что пока тут нечего отобразить. Как только появятся реальные задания ты увидишь их здесь",
    mediaFile: {
        url: "/img/wait.jpg"
    },
    cost: undefined,
    completed: true,
    browse: false
};

const emptyTaskAssigned = {
    title: 'Назначенное задание',
    description: "Похоже что пока тут нечего отобразить. Как только появятся реальные задания ты увидишь их здесь",
    mediaFile: {
        url: "/img/wait.jpg"
    },
    cost: undefined,
    completed: true,
    browse: false
};

const emptyTaskBonus = {
    title: 'Бонусное задание',
    description: "Похоже что пока тут нечего отобразить. Как только появятся реальные задания ты увидишь их здесь",
    mediaFile: {
        url: "/img/wait.jpg"
    },
    cost: undefined,
    completed: true,
    browse: false
};


export default OperationTasks;
