export const gameViewInitialState = {
    active: false,
    id: 0,
    title: '',
    description: '',
    conditions: '',
    image: undefined
};

function GameReducer(state, action) {

    if(action === 'hide'){
        return gameViewInitialState;
    }
    else {
        action.active = true;
        return action;
    }
}

export default GameReducer;
