import AuthModal from "../../modal/AuthModal";

const {useReducer} = require("react");


export let taskViewInitialState = {
    active: false,
    id: 0,
    title: '',
    description: '',
    conditions: '',
    cost: undefined,
    withTargetUser: undefined,
    taskGroupName: undefined,
    taskGroupType: undefined,
    duration: undefined,
    mediaFile: undefined,
    buyCount: undefined,
    useTime: undefined,

    //ПАРАМЕТРЫ ДЛЯ ОТОБРАЖЕНИЯ КУПЛЕННОГО ЗАДАНИЯ

    ownerUser: undefined,
    aimUser: undefined,
    taskUseStatus: undefined,
    duelResult: undefined
};

function TaskReducer(state, action) {
    console.log("TaskReducer");
    if(action === 'hide')
        return taskViewInitialState;
    else {
        action.active = true;
        return action;
    }
}

export default TaskReducer;
