import '../../css/custom.css';
import '../../css/team_competition.css';
import '../../css/neighbor.css';
import '../../css/components.css';
import React, {useContext, useEffect, useReducer, useState} from "react";
import EventRow from "../component/EventRow";
import TitleRow from "../component/TitleRow";
import TaskCard from "../component/TaskCard";
import Splitter from "../component/Splitter";
import {useSnackbar} from "notistack";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl} from "../schema/Environment";
import {Box, Stack, useTheme} from "@mui/system";
import {makeStyles} from "@mui/styles";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {AuthModalContext, CurrentStudentContext, PreloaderContext, TokenContext} from "../Contexts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserLock} from "@fortawesome/free-solid-svg-icons";
import {Progress} from "antd";

import sportImageFootball from '../../img/football.jpeg';
import sportImageVolleyball from '../../img/volleyball.jpeg';
import '../../css/selectBetKoef.css';
import DisclaimerModal from "./DisclaimerModal";
import {AxiosPostWithoutState} from "../axios/AxiosPost";

export default function BetsList() {

    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar()
    const [currentToken, setToken] = useContext(TokenContext);
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    const [modalActive, setModalActive] = useContext(AuthModalContext);

    const {loaded, error, data} = AxiosGet(apiUrl + "/bets/events/connect", "GET", null, enqueueSnackbar, currentToken);

    const [bet, setBet] = useState({
        event: null,
        eventId: 0,
        team: null,
        betType: null,
        amount: 0,
        specificResult: null,
        koef: 0
    });

    function handleParamChange(paramId, newValue) {
        if(paramId === 'BET')
            setBet(newValue)
        else
            setBet(prevState => ({
                ...prevState,
                [paramId]: newValue  // Используем вычисляемое свойство имени для динамического обновления
            }));
    }

    console.log("Bets");
    if (error) {
        if(error === 401)
            return (
                <div id="main-content-wrapper">
                    <Splitter/>
                    <div  onClick={e => setModalActive(true)} className="content-wrappers p-t-4">

                        <Box className="flex-row-vcenter-hcenter">
                            <FontAwesomeIcon icon={faUserLock} style={{color: theme.palette.info.dark, height: "10rem"}} />
                        </Box>
                        <Box className="flex-row-vcenter-hcenter" marginTop={"2rem"}>
                            <Typography variant="h2" textAlign={"center"} color={theme.palette.text.primary}>
                                Войдите чтобы продолжить
                            </Typography>
                        </Box>
                    </div>
                </div>
            )
        else
            return (
                <div id="main-content-wrapper">
                    <Splitter/>
                    <div className="content-wrappers" style={{paddingTop: "4rem"}}>
                        <EventRow time="" title="" error="Данные временно недоступны"/>
                    </div>
                </div>
            )
    } else if (loaded === false) {
        return (
            <div id="main-content-wrapper">
                <Splitter/>
                <div className="content-wrappers">
                    <div className="product-list-item-wrapper">
                        <TaskCard preload={true} appendClass="large"/>
                    </div>
                </div>
            </div>
        )
    } else if (data) {
        return (
            <>
                <div id="main-content-wrapper">
                    <Splitter/>
                    <div className="content-wrappers">

                        <TitleRow title="Ваша ставка"/>
                        <SelectedEventForBet bet={bet} handleParamChange={handleParamChange}/>

                        <TitleRow title="События"/>
                        {data.map((betEvent, index) => (
                            <BetEventCard betEvent={betEvent} handleParamChange={handleParamChange}/>
                        ))}
                    </div>
                </div>
                <DisclaimerModal/>
            </>
        );
    }

}

function SelectedEventForBet({bet, handleParamChange}) {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);
    const [currentToken, setToken] = useContext(TokenContext);

    let sportImage = '/';
    if (bet?.event?.betEventType === "FOOTBALL") {
        sportImage = sportImageFootball;
    } else if (bet?.event?.betEventType === "VOLLEYBALL") {
        sportImage = sportImageVolleyball;
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            handleParamChange("amount", inputValue);
        }
    };

    const handleBet = () => {

        const betDTO = {
            eventId: bet.event.id,
            teamId: bet.team?.id,
            betType: bet.betType,
            amount: bet.amount,
            specificResult: bet.specificResult
        }
        console.log(betDTO)
        debugger;

        setPreloaderModalIsActive(true)
        AxiosPostWithoutState(apiUrl + "/bets/create", null, betDTO, currentToken).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar(error, {variant: "error"});
                console.log("Произошла ошибка при отправке ставки: \n" + error);
            }
            else {
                enqueueSnackbar("Сохранено", {variant: "success"});
            }
            setPreloaderModalIsActive(false);
        }).catch(error => {
            enqueueSnackbar("Произошла ошибка при отправке ставки", {variant: "error"});
            console.log("Произошла ошибка при отправке ставки: \n" + error);
            setPreloaderModalIsActive(false);
        });
    };

    if(bet.event === null)
        return (<></>);
    else
        return (
            <Box className="border background-param" borderRadius={"2rem"} p={"4rem"} mb={"4rem"} height={"fit-content"} style={{backgroundImage: 'url(' + sportImage + '), linear-gradient(180deg, rgb(0 0 0 / 44%), rgb(0 0 0 / 62%))'}}>
                <Box className="flex-row-vcenter-hcenter"  mt={"2rem"}>
                    <Typography variant="h1" color={theme.palette.text.contrast}>
                        {bet.event.team1.name} - {bet.event.team2.name}
                    </Typography>
                </Box>
                {bet.betType === 'WIN' ?
                    <Box className="flex-row-vcenter-hcenter" mt={"2rem"}>
                        <Typography variant="h2" color={theme.palette.text.contrast}>
                            Победят {bet.team.name}
                        </Typography>
                    </Box>
                    :
                    <></>
                }
                {bet.betType === 'DRAW' ?
                    <Box className="flex-row-vcenter-hcenter" mt={"2rem"}>
                        <Typography variant="h2" color={theme.palette.text.contrast}>
                            Ничья
                        </Typography>
                    </Box>
                    :
                    <></>
                }
                {bet.betType === 'SPECIFIC_RESULT' ?
                    <Box className="flex-row-vcenter-hcenter" mt={"2rem"}>
                        <Typography variant="h2" color={theme.palette.text.contrast}>
                            Счет {bet.specificResult}
                        </Typography>
                    </Box>
                    :
                    <></>
                }

                <Box className="flex-row-vcenter-hcenter" mt={"5rem"}>
                    <Typography variant="h2" color={theme.palette.text.contrast}>
                        Коэффициент: {roundToTwoDecimalPlaces(bet.koef)}
                    </Typography>
                </Box>

                <Box className="flex-row-vcenter-hcenter" mt={"5rem"}>
                    <Typography variant="h3" textAlign={"center"} color={theme.palette.text.contrast}>
                        В случае правильного прогноза: {roundToInt(bet.amount * bet.koef)}
                    </Typography>
                </Box>

                <Box className="flex-row-vcenter-hcenter" mt={"2rem"}>
                    <input
                        type="text"
                        value={bet.amount}
                        onChange={handleInputChange}
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '5px',
                            color: 'black',
                            padding: '2rem',
                            fontSize: '4rem',
                            fontWeight: '600',
                            border: '1px solid #ccc',
                            outline: 'none'
                        }}
                    />
                </Box>

                <Button variant="contained" sx={{width: '100%', marginTop: '4rem', height: '10rem', borderRadius: '10rem', backgroundColor: theme.palette.primary.contrast}} onClick={handleBet}>
                    <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.primary}>
                        Поставить
                    </Typography>
                </Button>
            </Box>
        )

}

function BetEventCard({betEvent, handleParamChange}) {
    const theme = useTheme();
    const [currentToken, setToken] = useContext(TokenContext);
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    const [modalActive, setModalActive] = useContext(AuthModalContext);
    const [bet, setBet] = useState(0);
    const [betError, setBetError] = useState(false);
    const [betSuccess, setBetSuccess] = useState(false);
    const [betSuccessMessage, setBetSuccessMessage] = useState("");
    const [betErrorMessage, setBetErrorMessage] = useState("");
    const [betLoading, setBetLoading] = useState(false);


    const [event, setEvent] = useState({
        id: betEvent.id,
        startTime: betEvent.startTime,
        team1: betEvent.team1,
        team2: betEvent.team2,
        oddsTeam1: roundToTwoDecimalPlaces(betEvent.oddsTeam1),
        oddsTeam2: roundToTwoDecimalPlaces(betEvent.oddsTeam2),
        oddsDraw: roundToTwoDecimalPlaces(betEvent.oddsDraw),
        betEventType: betEvent.betEventType,
        specificResultOdds: betEvent.specificResultOdds
    });

    let sportImage = '';
    if (event.betEventType === "FOOTBALL") {
        sportImage = sportImageFootball;
    } else if (event.betEventType === "VOLLEYBALL") {
        sportImage = sportImageVolleyball;
    }

    return (
        <Box className="border background-param" borderRadius={"2rem"} p={"4rem"} mb={"4rem"} height={"fit-content"} style={{backgroundImage: 'url(' + sportImage + '), linear-gradient(180deg, rgb(0 0 0 / 44%), rgb(0 0 0 / 62%))'}}>
            <Box className="flex-row-vcenter-hcenter">
                <Typography variant="hLarge" color={theme.palette.text.contrast}>
                    {extractTime(event.startTime)}
                </Typography>
            </Box>
            <Box className="flex-row-vcenter-hcenter"  mt={"2rem"}>
                <Typography variant="h1" color={theme.palette.text.contrast}>
                    {event.team1.name} - {event.team2.name}
                </Typography>
            </Box>

            <Box className="flex-row-vcenter-hcenter" mt={"4rem"}>
                <Typography variant="h1" color={theme.palette.text.contrast}>
                    Cчет
                </Typography>
            </Box>

            <Box className="flex-row-vcenter-hcenter" mt={"2rem"}>
                <SelectComponent options={event.specificResultOdds} betEvent={event} handleParamChange={handleParamChange}/>
            </Box>

            <Box className="flex-row-vcenter-hcenter" mt={"6rem"}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <BetBox name={event.team1.name} betType={'WIN'} team={event.team1} koef={event.oddsTeam1}  event={event} handleParamChange={handleParamChange}/>
                    </Grid>
                    <Grid item xs={4}>
                        <BetBox name={"Ничья"} betType={'DRAW'} team={null} koef={event.oddsDraw}  event={event} handleParamChange={handleParamChange}/>
                    </Grid>
                    <Grid item xs={4}>
                        <BetBox name={event.team2.name} betType={'WIN'} team={event.team2} koef={event.oddsTeam2}  event={event} handleParamChange={handleParamChange}/>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );



}

function BetBox({name, betType, team, koef, event, handleParamChange}) {
    const theme = useTheme();
    console.log(name, koef);
    function handleSelect(team, betType, koef) {
        handleParamChange('BET',{
            event: event,
            eventId: event.id,
            team: team,
            betType: betType,
            amount: 0,
            specificResult: null,
            koef: koef
        });
    }

    return(
        <Box bgcolor={"whitesmoke"} borderRadius={"2rem"} p={"1rem"} onClick={() => handleSelect(team, betType, koef)}>
            <Typography variant="h3" textAlign={"center"} color={theme.palette.text.primary}>{name}</Typography>
            <Typography variant="h3" textAlign={"center"} color={theme.palette.text.primary}>{koef}</Typography>
        </Box>
    )
}

const SelectComponent = ({ options, betEvent, handleParamChange }) => {
    const [selectedValue, setSelectedValue] = useState('');
    const sortedOptions = Object.entries(options).sort((a, b) => {
        const [scoreA] = a[0].split(':').map(Number);
        const [scoreB] = b[0].split(':').map(Number);
        return scoreA - scoreB || parseInt(a[0].split(':')[1], 10) - parseInt(b[0].split(':')[1], 10);
    });

    const handleSelectLocal = (event) => {
        setSelectedValue(event.target.value);
        console.log("Selected value:", event.target.value);

        handleParamChange('BET',{
            event: betEvent,
            eventId: event.id,
            team: null,
            betType: "SPECIFIC_RESULT",
            amount: 0,
            specificResult: event.target.value,
            koef: sortedOptions.find(([score]) => score === event.target.value)[1]
        });
    };

    return (
        <div className="select-wrapper">
            <select className="custom-select" onChange={handleSelectLocal} value={selectedValue}>
                {sortedOptions.map(([score, odds]) => (
                    <option key={score} value={score}>
                        {score} - {odds.toFixed(2)}
                    </option>
                ))}
            </select>
            <div className="custom-select-arrow"></div>
        </div>
    );
};

function extractTime(dateTimeString) {
    // Извлекаем время из строки
    const timeWithSeconds = dateTimeString.split(' ')[1];
    // Оставляем только часы и минуты
    const timeWithoutSeconds = timeWithSeconds.slice(0, 5);
    return timeWithoutSeconds;
}

function roundToTwoDecimalPlaces(number) {
    return parseFloat(number.toFixed(2));
}

function roundToInt(number) {
    return parseFloat(number.toFixed(0));
}

// function SelectedBet({betEventType, }) {
//     return parseFloat(number.toFixed(2));
// }


