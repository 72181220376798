import {PreloaderContext} from "./Contexts";
import * as React from 'react';
import {useContext} from "react";
import {CircularProgress} from "@material-ui/core";
import {Box} from "@mui/system";


export default function Preloader (){
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);
    if(preloaderModalIsActive)
        return (
            <Box bgcolor={"#000000b8"} className="absolute-position flex-col-vcenter-hcenter" style={{display: preloaderModalIsActive, zIndex: 1000}}>
                <CircularProgress style={{width: "15rem", height: "15rem"}} />
            </Box>
        )
    else
        return (<></>)
}
