import '../../css/custom.css';
import '../../css/neighbor.css';
import Splitter from "../component/Splitter";
import React, {useContext} from "react";
import {AuthModalContext, CurrentStudentContext, NotifyModalContext, TokenContext} from "../Contexts";
import {apiUrl} from "../schema/Environment";
import {Button, Typography} from "@mui/material";
import {Box, useTheme} from "@mui/system";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserLock, faUsers, faUser, faHouseUser} from "@fortawesome/free-solid-svg-icons";
import TaskCard from "../component/TaskCard";
import {CustomMedia} from "../component/Inputs/MediaUploaders";
import {AxiosPostWithoutState} from "../axios/AxiosPost";
import {useSnackbar} from "notistack";

function UserDataSheet(props) {

    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();

    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    const [currentToken, setCurrentToken] = useContext(TokenContext);
    const [notifyModalState, setNotifyModalState] = useContext(NotifyModalContext);

    console.log(currentStudent);


    function logout() {
        setCurrentToken();
        setCurrentStudent();
    }

    const handleParticipationChange = () => {
        currentStudent.confirmedParticipation = true;
        AxiosPostWithoutState(apiUrl + "/student/save", null, currentStudent, null).then(({
                                                                                              loaded,
                                                                                              error,
                                                                                              data
                                                                                          }) => {
            if (error) {
                enqueueSnackbar(error, {variant: 'error'});
                console.log("Произошла ошибка при сохранении: \n" + error);
            } else {
                setCurrentStudent(data);
            }
        })
            .catch((error) => {
                enqueueSnackbar("Возникла непредвиденная ошибка, повторите позже", {variant: 'error'});
            });

    }


    const handleAvatarChange = (mediaFile) => {
        currentStudent.avatar = mediaFile;
        AxiosPostWithoutState(apiUrl + "/student/save", null, currentStudent, null).then(({
                                                                                         loaded,
                                                                                         error,
                                                                                         data
                                                                                     }) => {
            if (error) {
                enqueueSnackbar(error, {variant: 'error'});
                console.log("Произошла ошибка при сохранении: \n" + error);
            } else {
                setCurrentStudent(data);
            }
        })
            .catch((error) => {
                enqueueSnackbar("Возникла непредвиденная ошибка, повторите позже", {variant: 'error'});
            });

    }

    if (props.token === undefined || props.token === null) {
        return (
            <div>
                <div>
                    <Splitter/>
                    <Box className="flex-row-vcenter-hcenter">
                        <FontAwesomeIcon icon={faUserLock} style={{color: theme.palette.info.dark, height: "10rem"}} />
                    </Box>
                    <Box className="flex-row-vcenter-hcenter" marginTop={"2rem"}>
                        <Typography variant="h2" textAlign={"center"} color={theme.palette.text.primary}>
                            Войдите чтобы продолжить
                        </Typography>
                    </Box>
                </div>
            </div>
        )
    }
    else if(!currentStudent){
        return (
            <>
                <Splitter/>
                <div className="content-wrappers">
                    <div className="product-list-item-wrapper">
                        <TaskCard preload={true} appendClass="large"/>
                    </div>
                </div>
            </>
        )
    }
    else {

        let confirmParticipantInputElem = '';
        if(currentStudent.confirmedParticipation === true)
            confirmParticipantInputElem = <input type="checkbox" checked={true} disabled={true} className="confirm-participant-input"/>;
        else
            confirmParticipantInputElem = <input type="checkbox" className="confirm-participant-input" onClick={handleParticipationChange}/>;

        console.log("Выйти");

        return (

            <div>
                <Splitter/>
                <div className="content-wrappers p-t-4">
                    {/*<TitleRow title="Личный кабинет"/>*/}
                    <Box className="flex-row-vcenter-hcenter">
                        <CustomMedia loadedFile={currentStudent.avatar} type={"IMAGE"}
                                     targetMediaPath={"/user/avatar"}
                                     onChangeFunction={mediaFile => handleAvatarChange(mediaFile)}/>
                    </Box>
                    <Box width={"100%"} marginTop={"2rem"} className={"flex-col-vcenter-hcenter"}>
                        <Box width={"40%"}>
                            <Button variant="contained" sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem', borderRadius: '10rem', backgroundColor: theme.palette.error.dark}} onClick={logout}>
                                <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.contrast}>
                                    Выйти
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                    <div>
                        <Box className="flex-row-space-between m-t-6">
                            <Box width={"15%"} className={"flex-row-vcenter-hcenter"}>
                                <FontAwesomeIcon icon={faUser} style={{color: theme.palette.info.dark, height: "7rem"}} />
                            </Box>
                            <Box width={"80%"} maxWidth={"80%"} sx={{overflow: "hidden"}}>
                                <Typography variant="bodyLarge" sx={{textWrap :"nowrap", overflow: "hidden"}} textAlign={"left"} color={theme.palette.text.primary}>
                                    {currentStudent.fullName}
                                </Typography>
                            </Box>
                        </Box>

                        <Box className="flex-row-space-between m-t-4">
                            <Box width={"15%"} className={"flex-row-vcenter-hcenter"}>
                                <FontAwesomeIcon icon={faUsers} style={{color: theme.palette.info.dark, height: "7rem"}} />
                            </Box>
                            <Box width={"80%"}>
                                <Typography variant="bodyLarge" sx={{textWrap :"nowrap", overflow: "hidden"}} textAlign={"left"} color={theme.palette.text.primary}>
                                    {currentStudent.team?.name}
                                </Typography>
                            </Box>
                        </Box>

                        <Box className="flex-row-space-between m-t-4">
                            <Box width={"15%"} className={"flex-row-vcenter-hcenter"}>
                                <FontAwesomeIcon icon={faHouseUser} style={{color: theme.palette.info.dark, height: "7rem"}} />
                            </Box>
                            <Box width={"80%"}>
                                <Typography variant="bodyLarge" sx={{textWrap :"nowrap", overflow: "hidden"}} textAlign={"left"} color={theme.palette.text.primary}>
                                    {currentStudent.roomNumber}
                                </Typography>
                            </Box>
                        </Box>

                    </div>
                    <Box className="flex-row-space-between m-t-6">
                        <Box width={"80%"}>
                            <Box>
                                <Typography variant="body1" sx={{textWrap :"nowrap", overflow: "hidden"}} textAlign={"left"} color={theme.palette.text.primary}>
                                    Участие в игре <strong>Насоли соседу</strong>
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                                {confirmParticipantInputElem}
                        </Box>
                    </Box>
                </div>



            </div>
        );
    }
}


export default UserDataSheet;
