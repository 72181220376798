import {useTheme} from "@mui/system";
import {useSnackbar} from "notistack";
import React, {useContext, useEffect, useState} from "react";
import {AxiosPostWithoutState} from "../axios/AxiosPost";
import {apiUrl} from "../schema/Environment";
import {Box, Button, Grid, Typography} from "@mui/material";
import LogoImage from "../../img/logo_blue.png";

export default function TelegramAuth() {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();

    const [user, setUser] = useState(
        {
            username: '',
            password: ''
        });

    const handleFieldChange = (fieldName, fieldValue) => {
        setUser((prevFormData) => ({
            ...prevFormData,
            [fieldName]: fieldValue,
        }));
    };

    const urlParams = new URLSearchParams(window.location.search);
    const chatId = urlParams.get('chatId');

    if(!chatId)
        enqueueSnackbar("Авторизация доступна только при переходе из вашего телеграмма", { variant: 'error' });

    const loginUser = (e) => {
        e.preventDefault();

        if(user.username.length === 0 || user.password.length === 0)
            enqueueSnackbar("Заполните поля логин и пароль", { variant: 'info' });
        else if(!chatId)
            enqueueSnackbar("Авторизация доступна только при переходе из вашего телеграмма", { variant: 'error' });
        else {
            AxiosPostWithoutState(apiUrl + "/student/telegram/bind", {"chatId": chatId}, user, null).then(({
                                                                                         loaded,
                                                                                         error,
                                                                                         data
                                                                                     }) => {
                if (error) {
                    enqueueSnackbar(error, {variant: 'error'});
                    console.log("Произошла ошибка при получении пользователя: \n" + error);
                } else {
                    enqueueSnackbar("Ваш телеграмм аккаунт успешно привязан", { variant: 'info' });
                }
            })
                .catch((error) => {
                    enqueueSnackbar("Произошла ошибка, попробуйте позднее", {variant: 'error'});
                    // обрабатываем ошибку запроса
                });
        }
    }

    console.log("TelegramAuth ")

        return (

            <Box id={"auth-modal"} className="absolute-position flex-col" bgcolor={"whitesmoke"} zIndex={5}>
                <Box sx={{flexGrow:1}} className={"flex-col-vcenter-hcenter"}>
                    <Box sx={{transform: "translate(0, -10rem)"}}>
                        <Box height={"40rem"} className="flex-row-vcenter-hcenter m-b-1">
                            <img src={LogoImage} style={{height: 'inherit'}}/>
                        </Box>

                        <Box marginTop={"4rem"} marginBottom={"4rem"}>
                            <Typography variant="h1" textAlign={"center"} color={theme.palette.text.primary}>Авторизация</Typography>
                        </Box>
                        <Grid container spacing={1}>
                            <Grid  item xs={12}>
                                <Box  className="field-input flex-row-vstart-hcenter">
                                    <input onChange={e => handleFieldChange('username', e.target.value)}
                                           placeholder="Логин"/>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box  className="field-input flex-row-vstart-hcenter">
                                    <input type='password' onChange={e => handleFieldChange('password', e.target.value)}
                                           placeholder="Пароль"/>
                                </Box>
                            </Grid>
                        </Grid>
                        <Button variant="contained" sx={{ width: '100%', marginTop: '4rem', marginBottom: '1rem', borderRadius: '10rem', backgroundColor: theme.palette.info.dark}} onClick={e => loginUser(e)}>
                            <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.contrast}>
                                Войти
                            </Typography>
                        </Button>
                    </Box>
                </Box>

            </Box>
        )

}
