import '../../css/custom.css';
import '../../css/team_competition.css';
import '../../css/neighbor.css';
import '../../css/components.css';
import React, {useContext, useEffect, useReducer, useState} from "react";
import EventRow from "../component/EventRow";
import TitleRow from "../component/TitleRow";
import TaskCard from "../component/TaskCard";
import Splitter from "../component/Splitter";
import {useSnackbar} from "notistack";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl} from "../schema/Environment";
import {Box, Stack, useTheme} from "@mui/system";
import {makeStyles} from "@mui/styles";
import {Grid, TextField, Typography} from "@mui/material";
import {AuthModalContext, CurrentStudentContext, TokenContext} from "../Contexts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserLock} from "@fortawesome/free-solid-svg-icons";
import {Progress} from "antd";

export default function Legends({code}) {

    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar()
    const [currentToken, setToken] = useContext(TokenContext);
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    const [modalActive, setModalActive] = useContext(AuthModalContext);

    let params = null;
    if(code)
        params = {"code": code};
    const {loaded, error, data} = AxiosGet(apiUrl + "/legend/connect", "GET", params, enqueueSnackbar, currentToken);

    console.log("Legends");
    if (error) {
        if(error === 401)
            return (
                <div id="main-content-wrapper">
                    <Splitter/>
                    <div  onClick={e => setModalActive(true)} className="content-wrappers p-t-4">

                        <Box className="flex-row-vcenter-hcenter">
                            <FontAwesomeIcon icon={faUserLock} style={{color: theme.palette.info.dark, height: "10rem"}} />
                        </Box>
                        <Box className="flex-row-vcenter-hcenter" marginTop={"2rem"}>
                            <Typography variant="h2" textAlign={"center"} color={theme.palette.text.primary}>
                                Войдите чтобы продолжить
                            </Typography>
                        </Box>
                    </div>
                </div>
            )
        else
            return (
                <div id="main-content-wrapper">
                    <Splitter/>
                    <div className="content-wrappers" style={{paddingTop: "4rem"}}>
                        <EventRow time="" title="" error="Данные временно недоступны"/>
                    </div>
                </div>
            )
    } else if (loaded === false) {
        return (
            <div id="main-content-wrapper">
                <Splitter/>
                <div className="content-wrappers">
                    <div className="product-list-item-wrapper">
                        <TaskCard preload={true} appendClass="large"/>
                    </div>
                </div>
            </div>
        )
    } else if (data) {
        return (
            <div id="main-content-wrapper">
                <Splitter/>
                <div className="content-wrappers">

                    <TitleRow title="Легенды"/>

                    <Box className="flex-row-vcenter-hcenter">
                        <Progress type="circle"  percent={parseInt(currentStudent?.legendSequenceId === 0 ? 100 : data?.progress * 100, 10)} strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} />
                    </Box>

                    <Box marginTop={"4rem"}>
                        <Typography variant="h2" textAlign={"center"} color={theme.palette.text.primary}>
                            Текущее задание
                        </Typography>
                    </Box>

                    <Box marginTop={"4rem"}>
                        <Typography variant="body1" textAlign={"left"} color={theme.palette.text.primary}>
                            {currentStudent?.legendSequenceId !== 0  ?
                                data?.point?.question
                            :
                                <>
                                    Вы завершили прохождение легенд СОЛ Бауманец
                                </>}
                        </Typography>
                    </Box>


                </div>
            </div>
        );
    }

}
