import '../../css/custom.css';
import React, {useEffect, useState} from "react";
import EventRow from "../component/EventRow";
import TitleRow from "../component/TitleRow";
import Splitter from "../component/Splitter";
import TaskCard from "../component/TaskCard";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import {useSnackbar} from "notistack";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl} from "../schema/Environment";
import {Grid} from "@mui/material";
import {Box, styled} from "@mui/system";

const CustomGridContainer = styled(Grid)(({ theme }) => ({
    '& > .MuiGrid-item': {
        paddingLeft: '30px !important',
        paddingTop: '30px !important',
    },
}));

function TaskListByGroupNameAndGroupType(props) {


    if(props.preload){
        return (
            <div className="product-list-item-wrapper inline-flex">
                <TaskCard preload={true}/>
                <TaskCard preload={true}/>
            </div>
        )
    }
    else
        return (
            <Content props={props}/>
        )

}

function Content({props}) {
    const {enqueueSnackbar} = useSnackbar()
    const {loaded, error, data} = AxiosGet(apiUrl + "/neighbor/task/findTaskListByGroupNameAndGroupType", "GET", {"taskGroupType": props.groupType, "taskGroupName": props.groupName}, enqueueSnackbar);

    if (error) {
        return (<EventRow time="" title="" error="Данные временно недоступны"/>)
    } else if (loaded === false) {
        return (
            <div className="product-list-item-wrapper inline-flex">
                <TaskCard preload={true}/>
                <TaskCard preload={true}/>
            </div>
        )
    } else {

        console.log("Content")
        return (
            <Box sx={{padding: "1rem", position: "relative"}}>
                <CustomGridContainer container spacing={4}>
                    {data.map((item,index) => (
                        <Grid item xs={6} key={index}>
                            <TaskCard
                                key={index}
                                id={item.id}
                                title={item.title}
                                description={item.description}
                                conditions={item.conditions}
                                cost={item.cost}
                                withTargetUser={item.withTargetUser}
                                taskGroupName={item.taskGroupName}
                                taskGroupType={item.taskGroupType}
                                duration={item.duration}
                                mediaFile={item.mediaFile}
                                buyCount={item.buyCount}
                                ownerUser={item.ownerUser}
                                aimUser={item.aimUser}
                                taskUseStatus={item.taskUseStatus}
                                duelResult={item.duelResult}
                                useTime={item.useTime}
                                browse={true}/>
                        </Grid>
                    ))}
                </CustomGridContainer>
            </Box>
        );
    }
}


export default TaskListByGroupNameAndGroupType;
