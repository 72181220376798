import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import {useTheme} from "@mui/system";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '800px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const DisclaimerModal = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(true);
    }, []);

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style} width={"90vw"}>
                    <Typography id="modal-title" variant="h1" component="h2">
                        Внимание!
                    </Typography>
                    <Box  sx={{ mt: 2 }}>
                        <Typography id="modal-description" variant="bodyLarge">
                            Оставаясь на этой странице, вы соглашаетесь с нашими правилами и условиями.
                            Данная игра не является азартной и не связана с реальными деньгами.
                        </Typography>
                    </Box>

                    <Box  sx={{ mt: 2 }}>
                        <Typography id="modal-description" variant="bodyLarge" sx={{ mt: 2 }}>
                            Все ставки осуществляются с использованием внутриигровой валюты, предназначенной исключительно для
                            развлечения. Мы рекомендуем ответственно подходить к участию в игре.
                        </Typography>
                    </Box>

                    <Box  sx={{ mt: 2 }}>
                        <Typography id="modal-description" variant="bodyLarge" sx={{ mt: 2 }}>
                            Приятного времяпрепровождения!
                        </Typography>
                    </Box>

                    <Box  sx={{ mt: 2 }}>
                        <Button variant="contained" sx={{width: '100%', marginTop: '4rem', height: '10rem', borderRadius: '10rem', backgroundColor: theme.palette.info.dark}} onClick={handleClose}>
                            <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.contrast}>
                                Принять
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default DisclaimerModal;
