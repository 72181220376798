import Grid from "@mui/material/Grid";
import MediaUploader, {UploadedFile} from "../Inputs/MediaUploader";
import React, {useState} from "react";
import {Box, Typography} from "@mui/material";


export function CustomMediaList({mediaFiles, onChangeFunction, type = 'ALL', targetMediaPath = "", disabled = false}){
    const typeArray = type.split(", ");

    let imageHintForView = mediaFiles.filter(uploadedFile => uploadedFile?.fileExtensionType === 'IMAGE');
    let audioHintForView = mediaFiles.filter(uploadedFile => uploadedFile?.fileExtensionType === 'AUDIO');
    let videoHintForView = mediaFiles.filter(uploadedFile => uploadedFile?.fileExtensionType === 'VIDEO');

    if(disabled === false){
        imageHintForView.push(new UploadedFile("IMAGE",null));
        audioHintForView.push(new UploadedFile("AUDIO",null));
        videoHintForView.push(new UploadedFile("VIDEO",null));
    }

    const [imageHintList, setImageHintList] = useState(imageHintForView);
    const [audioHintList, setAudioHintList] = useState(audioHintForView);
    const [videoHintList, setVideoHintList] = useState(videoHintForView);

    return (
        <>
            <Grid container direction="row" spacing={2}>
                {( typeArray.includes('ALL') || typeArray.includes('IMAGE')) ?
                    <Grid item xs={4}>
                        <Box marginTop="1rem" marginBottom="1rem">
                            <Typography variant="h4" textAlign={"center"} color="textSecondary">Изображения</Typography>
                        </Box>
                        <Grid container>
                            {imageHintList.map((file, index) => (
                                <Grid item xs={12} key={index} >
                                    <MediaUploader loadedFile={file} type="IMAGE" handleChangeFile={onChangeFunction} disabled={disabled} targetMediaPath={targetMediaPath}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    :
                    <></>
                }
                {(typeArray.includes('ALL') || typeArray.includes('AUDIO')) ?
                    <Grid item xs={4}>
                        <Box marginTop="1rem" marginBottom="1rem">
                            <Typography variant="h4" textAlign={"center"} color="textSecondary">Аудио</Typography>
                        </Box>
                        <Grid container>
                            {audioHintList.map((file, index) => (
                                <Grid item xs={12} key={index} >
                                    <MediaUploader loadedFile={file} type="AUDIO" handleChangeFile={onChangeFunction} disabled={disabled} targetMediaPath={targetMediaPath}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    :
                    <></>
                }
                {(typeArray.includes('ALL') || typeArray.includes('VIDEO')) ?
                    <Grid item xs={4}>
                        <Box marginTop="1rem" marginBottom="1rem">
                            <Typography variant="h4" textAlign={"center"} color="textSecondary">Видео</Typography>
                        </Box>
                        <Grid container>
                            {videoHintList.map((file, index) => (
                                <Grid item xs={12} key={index} >
                                    <MediaUploader loadedFile={file} type="VIDEO" handleChangeFile={onChangeFunction} disabled={disabled} targetMediaPath={targetMediaPath}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    :
                    <></>
                }
            </Grid>
        </>
    )
}

export function CustomMedia({loadedFile, onChangeFunction, type, disabled = false, targetMediaPath = ""}){
    const handleMediaFilesChange = (file, type) => {
        if(onChangeFunction !== undefined) {
            if(type && type === "DELETE")
                onChangeFunction(null);
            else
                onChangeFunction(file);
        }
    }

    return (
        <>
            <MediaUploader loadedFile={loadedFile} type={type} handleChangeFile={handleMediaFilesChange} disabled={disabled} targetMediaPath={targetMediaPath}/>
        </>
    )
}
