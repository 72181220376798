import React, {useContext, useEffect, useState} from "react";
import {NotifyModalContext} from "../Contexts";
import LogoImage from '../../img/logo_white.png';
import {Box, Button, Typography} from "@mui/material";
import {useTheme} from "@mui/system";

function NotifyModal(){

    const theme = useTheme();
    const [visible, setVisible] = useState('invisible');
    let notifyModalStateParam = {
        class: 'inactive',
        text: 'hello'
    }
    const [notifyModalState, setNotifyModalState] = useContext(NotifyModalContext);

    useEffect(() => {
        if(notifyModalState.class !== 'inactive')
            setTimeout(
                function() {
                    setVisible('visible');
                },300
            );
    });

    function handleNotify(cssClass, text){
        notifyModalStateParam.class = cssClass;
        notifyModalStateParam.text = text;
        setVisible('invisible');
        setNotifyModalState(notifyModalStateParam);
    }


    var wrapperClass = 'hint-modal centeredWrapper ' + notifyModalState.class;
    return (
        <div onClick={e => handleNotify('inactive','')} className={wrapperClass + ' ' + visible}>
            <div className="hint-modal-inner">
                <Box height={"40rem"} className="flex-row-vcenter-hcenter">
                    <img src={LogoImage} style={{height: 'inherit'}}/>
                </Box>
                <Box marginTop={"1rem"} padding={"2rem"}>
                    <Typography variant="h2"  textAlign={"center"} color={theme.palette.text.contrast}>{notifyModalState.text}</Typography>
                </Box>

                <Box marginTop={"1rem"}>
                    <Button variant="contained" sx={{width: '100%', marginTop: '2rem', height: '10rem', borderRadius: '10rem', backgroundColor: theme.palette.info.contrast}} onClick={e => handleNotify('inactive','')}>
                        <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.primary}>
                            Продолжить
                        </Typography>
                    </Button>
                </Box>
                <br/>
                <br/>
            </div>
        </div>
    )
}

export default NotifyModal;
