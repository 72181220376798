import '../../css/custom.css';
import React, {useEffect, useState} from "react";
import EventRow from "../component/EventRow";
import TitleRow from "../component/TitleRow";
import Splitter from "../component/Splitter";
import TaskCard from "../component/TaskCard";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import {useSnackbar} from "notistack";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl} from "../schema/Environment";

/**
 * Param:
 * token - токен авторизации пользователя
 * taskType: bought, assigned
 */

function GameCards() {

    const {enqueueSnackbar} = useSnackbar()
    const {loaded, error, data} = AxiosGet(apiUrl + "/game/list", "GET", null, enqueueSnackbar);

    if (error) {
        return (
            <div id="main-content-wrapper">
                <Splitter/>
                <div className="content-wrappers">
                    <TitleRow title="Настольные игры"/>
                    <EventRow time="" title="" error="Данные временно недоступны"/>
                </div>
            </div>
        )
    } else if (loaded === false) {
        return (
            <div id="main-content-wrapper">
                <Splitter/>
                <div className="content-wrappers">
                    <TitleRow title="Настольные игры"/>
                    <div className="product-list-item-wrapper">
                        <TaskCard preload={true} appendClass="large"/>
                    </div>
                </div>
            </div>
        )
    } else {
        if (data.length > 0)
            return (
                <div id="main-content-wrapper">
                    <Splitter/>
                    <div className="content-wrappers">
                        <TitleRow title="Настольные игры"/>
                        <div className="product-list-item-wrapper">
                            {data.map(item => (
                                <TaskCard key={item.id} title={item.title} description={item.description}
                                          image={item.image} cost={item.cost} isGame={true} appendClass="large"/>
                            ))}
                        </div>
                    </div>
                </div>
            );
    }

}


export default GameCards;
