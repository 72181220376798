import {useEffect, useRef, useState} from "react";
import axios from "axios";


export const AxiosGet = (url, type, params, enqueueSnackbar, currentToken = null) => {
    const [state, setState] = useState({
        loaded: false,
        error: null,
        data: null,
    });

    const reqHeaders = {
        token: currentToken, // Установка заголовка "token" с заданным значением
    };

    const prevParams = useRef();

    const fetchData = async () => {
        if (type === "GET") {
            try {
                const response = await axios.get(url, { params: params, headers: reqHeaders });
                setState({
                    loaded: true,
                    error: null,
                    data: response.data,
                });
            } catch (error) {
                setState({
                    loaded: true,
                    error: error.response ? error.response.status : error.message,
                    data: null,
                });
                if(error.response !== undefined)
                    enqueueSnackbar(error.response.data.error, { variant: 'error' });
                else
                    enqueueSnackbar(error.message, { variant: 'error' });
                // return Promise.reject(error);
            }
        }
    };

    useEffect(() => {
        if(url === null)
            return;
        if (!prevParams.current || JSON.stringify(prevParams.current) !== JSON.stringify(params)) {
            fetchData();
            prevParams.current = params;
        }
    }, [url, type, params]);

    return { ...state };
};


export const AxiosGetWithoutState = async (url, params, currentToken) => {
        try {
            const reqHeaders = {
                token: currentToken, // Установка заголовка "token" с заданным значением
            };
            const response = await axios.get(url, {params: params, headers: reqHeaders});
            return {
                loaded: true,
                error: null,
                data: response.data,
            }
        } catch (error) {
                return {
                    loaded: true,
                    error: error.response ? error.response.data.error : error.message,
                    data: null
                }
        }
};
