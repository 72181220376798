import '../css/footer.css';
import '../css/header.css';

import logo_main from '../img/main_white.png';
import logo_neighbor from '../img/neighbor_white.png';
import logo_hobby_games from '../img/game_white.png';
import logo_rating from '../img/rating_white.png';
import logo_legends from '../img/legends_white.svg';
import logo_user_data from '../img/org_white.png';

import MainSheet from "./sheet/MainSheet";
import NeighborSheet from "./sheet/NeighborSheet";
import UserDataSheet from "./sheet/UserDataSheet";

import {SnackbarProvider, useSnackbar} from "notistack";

import {Link, NavLink, BrowserRouter as Router, Route, Switch, useNavigate} from "react-router-dom";
import {CacheRoute, CacheSwitch} from "react-router-cache-route";
import React, {useContext, useEffect, useReducer, useState} from "react";
import GameCards from "./class/GameCards";
import TeamRating from "./sheet/TeamRating";
import UseToken from "./auth/UseToken";
import {
    AuthModalContext,
    NotifyModalContext,
    TokenContext,
    TaskViewContext,
    TaskParamContext,
    GameParamContext, GameViewContext, DailyPresentViewContext, CurrentStudentContext, PreloaderContext
} from "./Contexts";
import AuthModal from "./modal/AuthModal";
import TaskView from "./neighbor/TaskView";
import taskReducer, {taskViewInitialState} from "./neighbor/reducers/TaskReducer";
import gameReducer, {gameViewInitialState} from "./neighbor/reducers/GameReducer";
import GameView from "./neighbor/GameView";
import NotifyModal from "./modal/NotifyModal";
import DailyPresentView from "./neighbor/DailyPresentView";
import {baseTheme} from "./schema/theme/BaseTheme";
import {ThemeProvider, Typography} from "@mui/material";
import {AxiosGetWithoutState} from "./axios/AxiosGet";
import {apiUrl} from "./schema/Environment";
import {Box, useTheme} from "@mui/system";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import Legends from "./sheet/Legends";
import TelegramAuth from "./sheet/TelegramAuth";
import NotFoundRedirect from "./sheet/NotFoundRedirect";
import notifyModalStateService, {setNotifyModalStateWithParam} from "./commonFunction/NotifyModalStateService";
import Preloader from "./Preloader";
import Vault from "./neighbor/Vault";
import Fair from "./sheet/Fair";
import BetsList from "./sheet/BetsList";





function PageRouter() {

    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    let notifyModalStateParam = {
        class: 'inactive',
        text: 'hello'
    }

    const {currentToken, setToken} = UseToken();
    const [modalActive, setModalActive] = useState(false);
    const [currentStudent, setCurrentStudent] = useState(undefined);
    const [notifyModalState, setNotifyModalState] = useState(notifyModalStateParam);
    const [taskParam, setTaskParam] = useReducer(taskReducer, taskViewInitialState);
    const [gameParam, setGameParam] = useReducer(gameReducer, gameViewInitialState);
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);
    const [dailyPresentViewActive, setDailyPresentViewActive] = useState(false);


    useEffect(() => {
        if(currentToken) {
            console.log("useEffect");
            AxiosGetWithoutState(apiUrl + "/student/findByToken", null, currentToken).then(({loaded, error, data}) => {
                if (error) {
                    enqueueSnackbar(error, { variant: 'error' });
                    console.log("Произошла ошибка при получении пользователя: \n" + error);
                } else {
                    setCurrentStudent(data);
                }
            }).catch(error => {
                console.log("Произошла ошибка при получении пользователя: \n" + error);
            });
        }

    }, currentToken)

    console.log("PageRouter");

    return (
        <ThemeProvider theme={baseTheme}>
        <DailyPresentViewContext.Provider value={[dailyPresentViewActive, setDailyPresentViewActive]}>
        <GameParamContext.Provider value={{gameParam, setGameParam}}>
        <TaskParamContext.Provider value={{taskParam, setTaskParam}}>
        <NotifyModalContext.Provider value={[notifyModalState, setNotifyModalState]}>
        <TokenContext.Provider value={[currentToken, setToken]}>
        <AuthModalContext.Provider value={[modalActive, setModalActive]}>
            <CurrentStudentContext.Provider value={[currentStudent, setCurrentStudent]}>
          <Router>
              <PageHeader/>
              <PageFooter/>

                  <CacheSwitch>
                      <CacheRoute path="/main">
                          <MainSheet/>
                      </CacheRoute>
                      <CacheRoute path="/neighbor">
                          <NeighborSheet/>
                      </CacheRoute>
                      <CacheRoute path="/hobby_games">
                          <GameCards/>
                      </CacheRoute>
                      <Route path="/rating">
                          <TeamRating />
                      </Route>

                      <Route path="/user_data">
                          <div id="main-content-wrapper">
                            <UserDataSheet token={currentToken}/>
                          </div>
                      </Route>
                      <Route path="/legends">
                          <Legends/>
                      </Route>

                      <Route path="/fair/:SOME_CODE" component={FairWithCode} />
                      <Route path="/legend/:SOME_CODE" component={LegendsWithCode} />

                      <Route path="/longCode/:SOME_CODE" component={LongCodeReciever}/>
                      <Route path="/questionCode/:SOME_CODE" component={QuestionCodeReciever}/>

                      <Route path="/auth/telegram">
                          <TelegramAuth />
                      </Route>

                      <Route path="/bets/list">
                          <BetsList />
                      </Route>


                      <Route component={NotFoundRedirect} />
                  </CacheSwitch>

              <AuthModal/>
              <TaskView/>
              <GameView/>
              <NotifyModal/>
              <DailyPresentView/>
          </Router>
            </CurrentStudentContext.Provider>
        </AuthModalContext.Provider>
        </TokenContext.Provider>
        </NotifyModalContext.Provider>
        </TaskParamContext.Provider>
        </GameParamContext.Provider>
        </DailyPresentViewContext.Provider>
        </ThemeProvider>
  );
}

const LegendsWithCode = ({ match }) => {
    console.log("LegendsWithCode");
    const code = match.params.SOME_CODE; // Получаем значение параметра SOME_CODE из URL
    return <Legends code={code} />;
};

const LongCodeReciever = ({ match }) => {
    console.log("LongCodeReciever");
    const code = match.params.SOME_CODE; // Получаем значение параметра SOME_CODE из URL
    return <Vault type={"long"} code={code} />;
};

const QuestionCodeReciever = ({ match }) => {
    console.log("QuestionCodeReciever");
    const code = match.params.SOME_CODE; // Получаем значение параметра SOME_CODE из URL
    return <Vault type={"question"} code={code} />;
};

const FairWithCode = ({ match }) => {
    console.log("FairWithCode");
    const code = match.params.SOME_CODE; // Получаем значение параметра SOME_CODE из URL
    return <Fair code={code} />;
};



function PageHeader() {
    const theme = useTheme();
    const [modalActive, setModalActive] = useContext(AuthModalContext);
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    return (
        <header className={"header-inner"}>
            {/*<Box marginRight={"4rem"}>*/}
            {/*    <NavLink exact to="/user_data" className=" menu-item centeredWrapper">*/}
            {/*        <FontAwesomeIcon icon={faUser} style={{color: "#ffffff", height: "5rem"}} />*/}
            {/*    </NavLink>*/}
            {/*</Box>*/}
            <Box width={"100%"} height={"100%"} className="flex-row-vcenter-hcenter" position={"absolute"}>
                <Typography variant="h1" textAlign={"center"} color={theme.palette.text.contrast}>SOLBAUMANEC</Typography>
            </Box>
            <Box width={"100%"} height={"100%"} zIndex={1} className="flex-row-vend" position={"absolute"}>

                    {!currentStudent ?
                        <Box marginRight={"4rem"} onClick={e => setModalActive(true)}>
                            <FontAwesomeIcon icon={faRightToBracket} style={{color: "#ffffff", height: "5rem"}}/>
                        </Box>
                        :
                        <NavLink exact to="/user_data">
                            <Box marginRight={"4rem"} >
                                <FontAwesomeIcon icon={faUser} style={{color: "#ffffff", height: "5rem"}} />
                            </Box>
                        </NavLink>
                    }
            </Box>
        </header>
    );
}

function PageFooter() {
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    return (
        <footer>
            <div className="footer-inner inline-wrapper-custom">
                <div className="centeredWrapper footer-box">
                    <NavLink exact to="/main" className="menu-item centeredWrapper">
                        <img className="icon-img" src={logo_main}/>
                        <div className="menu-title">Главная</div>
                    </NavLink>
                </div>
                <div className="centeredWrapper footer-box">
                    <NavLink exact to="/neighbor/student" className=" menu-item centeredWrapper">
                        <img className="icon-img" src={logo_neighbor}/>
                        <div className="menu-title">Сосед</div>
                    </NavLink>
                </div>
                {/*<div className="centeredWrapper footer-box">*/}
                {/*    <NavLink exact to="/hobby_games" className=" menu-item centeredWrapper">*/}
                {/*        <img className="icon-img" src={logo_hobby_games}/>*/}
                {/*        <div className="menu-title">Настолки</div>*/}
                {/*    </NavLink>*/}
                {/*</div>*/}
                <div className="centeredWrapper footer-box">
                    <NavLink exact to="/rating" className=" menu-item centeredWrapper">
                        <img className="icon-img" src={logo_rating}/>
                        <div className="menu-title">Зачет</div>
                    </NavLink>
                </div>
                <div className="centeredWrapper footer-box">
                    <NavLink exact to="/legends" className=" menu-item centeredWrapper">
                        <img className="icon-img" src={logo_legends}/>
                        {/*<FontAwesomeIcon className={"icon-img"} icon={faMagnifyingGlass} style={{color: "#ffffff"}} />*/}
                        {/*<FontAwesomeIcon icon={faXmark} style={{color: "#000000", height: "5rem"}} />*/}
                        <div className="menu-title">Легенды</div>
                    </NavLink>
                </div>
                {/*{currentStudent ?*/}
                {/*<div className="centeredWrapper footer-box">*/}
                {/*    <NavLink exact to="/user_data" className=" menu-item centeredWrapper">*/}
                {/*        <img className="icon-img" src={logo_user_data}/>*/}
                {/*        <div className="menu-title">ЛК</div>*/}
                {/*    </NavLink>*/}
                {/*</div>*/}
                {/*    :*/}
                {/*    <></>*/}
                {/*}*/}
            </div>
        </footer>
    );
}



export default PageRouter;
