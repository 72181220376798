import {AuthModalContext, CurrentStudentContext, PreloaderContext, TokenContext} from "../Contexts";
import React, {useContext, useEffect, useState} from "react";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import {apiUrl, authUrl} from "../schema/Environment";
import LogoImage from '../../img/logo_blue.png';
import {Box, Button, Grid, Typography} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faLock, faXmark} from "@fortawesome/free-solid-svg-icons";
import {AxiosPost, AxiosPostWithoutState} from "../axios/AxiosPost";
import {useSnackbar} from "notistack";
import {useTheme} from "@mui/system";
import {AxiosGet, AxiosGetWithoutState} from "../axios/AxiosGet";
import {SelectValueFullWidth} from "../component/Inputs/SelectValue";
import {CustomMedia} from "../component/Inputs/MediaUploaders";

export default function AuthModal() {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    const [currentToken, setCurrentToken] = useContext(TokenContext);
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);

    const [modalActive, setModalActive] = useContext(AuthModalContext);
    const [visible, setVisible] = useState('invisible');

    const [type, setType] = useState('login');

    const [user, setUser] = useState(
        {
            username: '',
            password: ''
        });

    const handleFieldChange = (fieldName, fieldValue) => {
        setUser((prevFormData) => ({
            ...prevFormData,
            [fieldName]: fieldValue,
        }));
    };

    const loginUser = (e) => {
        e.preventDefault();
        setCurrentToken(undefined);
        setPreloaderModalIsActive(true);

        if(user.username.length === 0 || user.password.length === 0)
            enqueueSnackbar("Заполните поля логин и пароль", { variant: 'info' });
        else {
            AxiosPostWithoutState(apiUrl + "/student/login", null, user, null).then(({
                                                                                       loaded,
                                                                                       error,
                                                                                       data
                                                                                   }) => {
                if (error) {
                    enqueueSnackbar(error, {variant: 'error'});
                    console.log("Произошла ошибка при получении пользователя: \n" + error);
                } else {
                    setCurrentStudent(data);
                    setCurrentToken(data.token);
                    setModalActive(false);
                }
                setPreloaderModalIsActive(false);
            })
                .catch((error) => {
                    enqueueSnackbar(error, {variant: 'error'});
                    // обрабатываем ошибку запроса
                    setModalActive(false);
                    setPreloaderModalIsActive(false);
                });
        }
    }

    useEffect(() => {
        if(visible !== 'visible' && modalActive)
            setTimeout(
                function() {
                    setVisible('visible');
                },300
            );
    });

    function handleModal(){
        setVisible('invisible');
        setModalActive(false);
    }

    console.log("AuthModal " + modalActive)

    var wrapperClass = 'absolute-position flex-col inactive';
    if(modalActive)
        wrapperClass = 'absolute-position flex-col';

    if(type === 'login')
        return (

            <Box id={"auth-modal"} className={wrapperClass} bgcolor={"whitesmoke"} zIndex={5} padding={"6rem 6rem"}>
                <Box className={"flex-row-space-between"}>
                    <Box>
                    </Box>
                    <Box onClick={handleModal}>
                        <FontAwesomeIcon icon={faXmark} style={{color: "#000000", height: "5rem"}} />
                    </Box>
                </Box>
                <Box sx={{flexGrow:1}} className={"flex-col-vcenter-hcenter"}>
                    <Box sx={{transform: "translate(0, -10rem)"}}>
                        <Box height={"40rem"} className="flex-row-vcenter-hcenter m-b-1">
                            <img src={LogoImage} style={{height: 'inherit'}}/>
                        </Box>

                        <Box marginTop={"4rem"} marginBottom={"4rem"}>
                            <Typography variant="h1" textAlign={"center"} color={theme.palette.text.primary}>Авторизация</Typography>
                        </Box>
                        <Grid container spacing={1}>
                            <Grid  item xs={12}>
                                <Box  className="field-input flex-row-vstart-hcenter">
                                    <input onChange={e => handleFieldChange('username', e.target.value)}
                                        placeholder="Логин"/>
                                </Box>
                                {/*<TextFieldRow name={"Логин"} value={user.username} onChangeFunction={ e => handleFieldChange('username', e.target.value)}/>*/}
                            </Grid>
                            <Grid item xs={12}>
                                <Box  className="field-input flex-row-vstart-hcenter">
                                    <input type='password' onChange={e => handleFieldChange('password', e.target.value)}
                                           placeholder="Пароль"/>
                                </Box>
                            </Grid>
                        </Grid>
                        <Button variant="contained" sx={{ width: '100%', marginTop: '4rem', marginBottom: '1rem', borderRadius: '10rem', backgroundColor: theme.palette.info.dark}} onClick={e => loginUser(e)}>
                            <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.contrast}>
                                Войти
                            </Typography>
                        </Button>

                        <Button variant="contained" sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem', borderRadius: '10rem', backgroundColor: theme.palette.info.contrastText}} onClick={e => setType("auth")}>
                            <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.primary}>
                                Регистрация
                            </Typography>
                        </Button>
                    </Box>
                </Box>

            </Box>
        )

    if(type === 'auth')
        return (<AuthContent setType={setType} wrapperClass={wrapperClass} handleModal={handleModal}/>)
}

function AuthContent({setType, handleModal, wrapperClass}){
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    const [currentToken, setCurrentToken] = useContext(TokenContext);
    const [modalActive, setModalActive] = useContext(AuthModalContext);
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);


    const {loaded: teamSetLoaded,  error: teamSetError, data: teamSetData} = AxiosGet(apiUrl + "/team/findAllForCurrentDate", "GET", null, enqueueSnackbar);


    const [newUser, setNewUser] = useState(
        {
            name: '',
            surname: '',
            teamId: undefined,
            roomNumber: undefined,
            username: '',
            password: '',
            avatar: undefined
        });

    const handleFieldChange = (fieldName, fieldValue) => {
        setNewUser((prevFormData) => ({
            ...prevFormData,
            [fieldName]: fieldValue,
        }));
    };

    let teamKeyValueSet;
    if(teamSetData) {
        teamKeyValueSet = teamSetData.map((team) => ({
            key: team.id,
            value: team.name,
        }));
    }


    const createNewUser = (e) => {
        e.preventDefault();
        setCurrentToken(undefined)

        if(newUser.name.length === 0
            || newUser.surname.length === 0
            || !newUser.roomNumber
            || newUser.username.length === 0
            || newUser.password.length === 0)
            enqueueSnackbar("Заполните все поля", { variant: 'info' });
        else if (!newUser.teamId)
            enqueueSnackbar("Выберите команду", { variant: 'info' });
        else if (!newUser.avatar)
            enqueueSnackbar("Загрузите фотографию", { variant: 'info' });
        else {
            setPreloaderModalIsActive(true);
            AxiosPostWithoutState(apiUrl + "/student/create", null, newUser, null).then(({
                                                                                          loaded,
                                                                                          error,
                                                                                          data
                                                                                      }) => {
                if (error) {
                    enqueueSnackbar(error, {variant: 'error'});
                    console.log("Произошла ошибка при получении пользователя: \n" + error);
                } else {
                    setCurrentStudent(data);
                    setCurrentToken(data.token);
                }
                setModalActive(false);
                setPreloaderModalIsActive(false);
            })
                .catch((error) => {
                    enqueueSnackbar(error, {variant: 'error'});
                    setModalActive(false);
                    setPreloaderModalIsActive(false);
                });
        }
    }

    console.log("teamKeyValueSet");


    return (

        <Box id={"auth-modal"}  className={wrapperClass} bgcolor={"whitesmoke"} zIndex={5} padding={"6rem 6rem"}>
            <Box className={"flex-row-space-between"}>
                <Box onClick={e => setType("login")}>
                    <FontAwesomeIcon icon={faArrowLeft} style={{color: "#000000", height: "5rem"}} />
                </Box>
                <Box onClick={handleModal}>
                    <FontAwesomeIcon icon={faXmark} style={{color: "#000000", height: "5rem"}} />
                </Box>
            </Box>
            <Box sx={{flexGrow:1}} className={"flex-col-vcenter-hcenter"}>
                <Box sx={{transform: "translate(0, -10rem)"}}>
                    {/*<Box height={"40rem"} className="flex-row-vcenter-hcenter m-b-1">*/}
                    {/*    <img src={LogoImage} style={{height: 'inherit'}}/>*/}
                    {/*</Box>*/}

                    {/*<Box marginTop={"4rem"} marginBottom={"4rem"}>*/}
                    {/*    <Typography variant="h1" textAlign={"center"} color={theme.palette.text.primary}>Регистрация</Typography>*/}
                    {/*</Box>*/}
                    <Grid container spacing={1}>
                        <Grid  item xs={12} className={"flex-col-vcenter-hcenter"}>
                            <CustomMedia loadedFile={newUser.avatar} type={"IMAGE"}
                                         targetMediaPath={"/user/avatar"}
                                         onChangeFunction={mediaFile => handleFieldChange('avatar', mediaFile)}/>
                        </Grid>
                        <Grid  item xs={12}>
                            <Box  className="field-input flex-row-vstart-hcenter">
                                <input onChange={e => handleFieldChange('name', e.target.value)}
                                       placeholder="Имя"/>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box  className="field-input flex-row-vstart-hcenter">
                                <input onChange={e => handleFieldChange('surname', e.target.value)}
                                       placeholder="Фамилия"/>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box  className="field-input flex-row-vstart-hcenter">
                                <input pattern="[0-9]*" onChange={e => handleFieldChange('roomNumber', e.target.value)}
                                       placeholder="Комната"/>
                            </Box>
                        </Grid>

                        <Grid  item xs={12}>
                            <Box  className="field-input flex-row-vstart-hcenter">
                                <input pattern="[a-z]*" onChange={e => handleFieldChange('username', e.target.value)}
                                       placeholder="Логин"/>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box  className="field-input flex-row-vstart-hcenter">
                                <input type='password' onChange={e => handleFieldChange('password', e.target.value)}
                                       placeholder="Пароль"/>
                            </Box>
                        </Grid>

                        <Grid item xs={12} marginTop={"2rem"}>
                            <Typography variant="h2" textAlign={"center"} color={theme.palette.text.primary}>Команда</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            {teamKeyValueSet ?
                                <SelectValueFullWidth title={""}
                                                      valuesSet={teamKeyValueSet}
                                                      selectedValue={newUser.teamId}
                                                      onChangeFunction={selectedValue => handleFieldChange('teamId', selectedValue)}
                                                      disabled={false}/>
                                : <></>
                            }
                        </Grid>
                    </Grid>

                    <Button variant="contained" sx={{ width: '100%', marginTop: '5rem', marginBottom: '1rem', borderRadius: '10rem', backgroundColor: theme.palette.info.contrastText}} onClick={createNewUser}>
                        <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.primary}>
                            Зарегистироваться
                        </Typography>
                    </Button>
                </Box>
            </Box>

        </Box>
    )
}

