import '../../css/custom.css';
import '../../css/team_competition.css';
import '../../css/neighbor.css';
import '../../css/components.css';
import React, {useEffect, useReducer, useState} from "react";
import EventRow from "../component/EventRow";
import TitleRow from "../component/TitleRow";
import TaskCard from "../component/TaskCard";
import Splitter from "../component/Splitter";
import {useSnackbar} from "notistack";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl} from "../schema/Environment";
import {Box, Stack, useTheme} from "@mui/system";
import {makeStyles} from "@mui/styles";
import {Grid, TextField, Typography} from "@mui/material";

const useStyleForInputSingleNumber = makeStyles((theme) => ({
    input: {
        width: '8rem', // задаем ширину ввода
        height: '8rem', // задаем высоту ввода
        borderRadius: '0.5rem', // задаем радиус закругления краев
        textAlign: 'center', // центрируем текст внутри ввода
        fontSize: '1.5rem', // задаем размер шрифта
        fontWeight: 'bold', // задаем жирность шрифта
    },
}));

function TeamRating(){

    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar()
    const {loaded, error, data: eventSet} = AxiosGet(apiUrl + "/event/findAll", "GET", null, enqueueSnackbar);
    const {loaded: teamListLoaded, error : teamListError, data: teamList} = AxiosGet(apiUrl + "/team/findAllBySmenaId", "GET", null, enqueueSnackbar);
    const classes = useStyleForInputSingleNumber();

    if (error || teamListError) {
            return (
                <div id="main-content-wrapper">
                    <Splitter/>
                    <div className="content-wrappers">
                        <TitleRow title="Командный зачет"/>
                        <EventRow time="" title="" error="Данные временно недоступны"/>
                    </div>
                </div>
            )
        } else if (loaded === false || teamListLoaded === false) {
            return (
                <div id="main-content-wrapper">
                    <Splitter/>
                    <div className="content-wrappers">
                        <TitleRow title="Командный зачет"/>
                        <div className="product-list-item-wrapper" >
                            <TaskCard preload={true} appendClass="large"/>
                        </div>
                    </div>
                </div>
            )
        } else if (eventSet && teamList) {
        console.log("filteredEvents");
            const filteredEvents = eventSet.filter(e => e.useInTeamCompetition);
            teamList.sort((a, b) => a.competitionBalance - b.competitionBalance);
        filteredEvents.forEach(event => {
            event.teamCompetitionResultList.sort((a, b) => {
                const teamA = teamList.find(team => team.id === a.team.id);
                const teamB = teamList.find(team => team.id === b.team.id);
                return teamList.indexOf(teamA) - teamList.indexOf(teamB);
            });
        });
            return (
                <div id="main-content-wrapper">
                    <Splitter/>
                    <div className="content-wrappers">

                        <TitleRow title="Командный зачет"/>

                        <Grid container spacing={1} sx={{mt: '1rem', mb: '2rem'}} direction="row">
                            <Grid item xs={4} className={"flex-row-vstart-hcenter"}>

                            </Grid>
                            <Grid item xs={8}>
                                <Grid container direction="row" spacing={1}>
                                    {teamList.map((team, index) => (
                                        <Grid key={index} item xs={3}>
                                            <Box key={index} className={"flex-col-vcenter-hcenter"}>
                                                <Typography variant="h2" textAlign={"center"}>
                                                    {team.name.slice(0, 1)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>

                        {filteredEvents.map((event, index) => (
                            <Box key={index}>
                                <Grid container spacing={1} sx={{mt: '1rem', mb: '2rem'}} direction="row">
                                    <Grid item xs={4} className={"flex-row-vstart-hcenter"}>
                                        <Typography variant="h1" marginLeft={"2rem"} textAlign={"left"}>
                                            {event.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container direction="row" spacing={1}>
                                            {event.teamCompetitionResultList.map((competitionResult, index) => (
                                                <Grid key={index} item xs={3}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                        <Box sx={{width: '10rem', height: '10rem', display: 'flex', alignItems: 'center', border: '0.5rem solid ' + competitionResult.team.color, borderRadius: '1rem' }}>
                                                            <Typography width={"100%"} variant="h1" textAlign="center" noWrap>{competitionResult.romanResult ? competitionResult.romanResult : '-'}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            ))}

                        <Grid container spacing={1} sx={{mt: '1rem', mb: '2rem'}} direction="row">
                            <Grid item xs={4} className={"flex-row-vstart-hcenter"}>
                                <Typography variant="h1" color={theme.palette.text.primary} marginLeft={"2rem"} textAlign={"left"}>
                                    Место
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container direction="row" spacing={1}>
                                    {teamList.map((team, index) => (
                                        <Grid key={index} item xs={3}>
                                            <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                <Box sx={{width: '10rem', height: '10rem', display: 'flex', alignItems: 'center', border: '0.5rem solid ' + team.color, borderRadius: '1rem' }}>
                                                    <Typography width={"100%"} variant="h1" textAlign="center" noWrap>{index + 1}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>

                    </div>
                </div>
            );
        }

}



export default TeamRating;
