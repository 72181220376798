import '../../css/custom.css';
import React, {useEffect, useState} from "react";
import EventRow from "../component/EventRow";
import TitleRow from "../component/TitleRow";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import {useSnackbar} from "notistack";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl} from "../schema/Environment";
import dayjs from "dayjs";

function NearEventList() {

    const {enqueueSnackbar} = useSnackbar()
    const {loaded, error, data} = AxiosGet(apiUrl + "/event/findAllForCurrentDate", "GET", null, enqueueSnackbar);
    console.log("NearEventList");

        if (error) {
            return (
                <div className="content-wrappers">
                    <TitleRow title="События на сегодня"/>
                    <EventRow time="" title="" error="Данные временно недоступны"/>
                </div>
            )
        } else if (loaded === false) {
            return (
                <div className="content-wrappers">
                    <TitleRow title="События на сегодня"/>
                    <EventRow time="" title="" preload={true}/>
                    <EventRow time="" title="" preload={true}/>
                </div>
            )
        } else {
            console.log("События на сегодня")
            const targetTime = new Date();
            targetTime.setHours(targetTime.getHours() + 1);

            const sortedEvents = data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
            return (
                <div className="content-wrappers">
                    <TitleRow title="События на сегодня"/>

                        {sortedEvents.map(item => (
                            <EventRow key={item.title}  time={formatDate(item.startTime)} title={item.title}/>
                        ))}
                </div>
            );
        }

}

function formatDate(dateString) {
    const date = dayjs(dateString);
    return date.format('HH:mm');
}


export default NearEventList;
