import { useState } from 'react';

function UseToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        try {
            const decodedToken = JSON.parse(atob(tokenString.split('.')[1]));
            const currentTime = Math.floor(Date.now() / 1000);

            if (decodedToken.exp < currentTime) {
                console.log("Время сессии истекло")
                return undefined;
            }
        }catch (e) {
            return undefined;
        }
        return tokenString
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', userToken);
        setToken(userToken);
    };

    return {
        currentToken: token,
        setToken: saveToken
    }
}

export default UseToken;
