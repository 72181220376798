import '../../css/custom.css';
import React, {useEffect, useState} from "react";
import EventRow from "../component/EventRow";
import TitleRow from "../component/TitleRow";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import {useSnackbar} from "notistack";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl} from "../schema/Environment";

function TeamCoinBalance() {

    const {enqueueSnackbar} = useSnackbar()
    const {loaded, error, data} = AxiosGet(apiUrl + "/team/findAllBySmenaId", "GET", null, enqueueSnackbar);

        if (error) {
            return (
                <div className="content-wrappers">
                    <TitleRow title="Coin Limited Balance"/>
                    <EventRow time="" title="" error="Данные временно недоступны"/>
                </div>
            )
        } else if (loaded === false) {
            return (
                <div className="content-wrappers">
                    <TitleRow title="Coin Limited Balance"/>
                    <EventRow time="" title="" preload={true}/>
                    <EventRow time="" title="" preload={true}/>
                    <EventRow time="" title="" preload={true}/>
                </div>
            );
        } else {
            console.log("here")
            return (
                <div className="content-wrappers">
                    <TitleRow title="Coin Limited Balance"/>
                    {data.map((team, index) => (
                        <EventRow key={index} time={team.coinBalance} title={team.name}/>
                    ))}
                </div>
            );
        }
}


export default TeamCoinBalance;
