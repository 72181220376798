import '../../css/components.css';
import '../../css/custom.css';
import React from "react";

class UsefulButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render()
    {
        return (
            <div className="useful-button-inner">
                <div className="useful-button show-hint" type="tg-hint">
                    <div className="justifyWrapper p-t-2">
                        <div className="useful-button-icon-img" style={{backgroundImage: 'url(' + this.props.image + ')'}}/>
                    </div>
                    <div className="useful-button-text">{this.props.title}</div>
                </div>
            </div>
        );
    }
}


export default UsefulButton;
