import React, {useContext} from "react";
import '../../css/taskModal.css';
import '../../css/header.css';
import '../../css/custom.css';
import {GameParamContext} from "../Contexts";

function GameView() {
    const {gameParam, setGameParam} = useContext(GameParamContext);

        return (
            <div className={gameParam.active ? 'task-data-wrapper active' : 'task-data-wrapper'} >
                <div className="task-view page" id="buy-task-container">
                    <div className="header-inner task-modal">
                        SOLBAUMANEC
                        <div  onClick={e => setGameParam('hide')} className="abs-right-icon header-right-icon">
                            <img className="right-icon-img" src="/img/close_white.png"/></div>
                    </div>
                    <div className="task-image" style={{backgroundImage: 'url(' + gameParam.image +'),linear-gradient(90deg, rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0.22))'}}>
                        <div className="task-data-cover"/>
                    </div>
                    <div >
                        <div className="content-wrappers">
                            <div className="task-title title-row">{gameParam.title}</div>
                            <div className="task-sub-text">Описание</div>
                            <div className="task-text task-description mb-5">
                                {gameParam.description}
                            </div>

                            <div className="task-sub-text">Условия</div>
                            <div className="task-text p-b-5">{gameParam.conditions}</div>
                        </div>
                    </div>
                </div>
            </div>
        );

}


export default GameView;
