import '../../css/custom.css';
import '../../css/preloader.css';
import React, {useEffect, useState} from "react";
import TitleRow from "../component/TitleRow";
import EventRow from "../component/EventRow";
import {apiUrl, staticPath} from "../schema/Environment";
import {AxiosGet} from "../axios/AxiosGet";
import {useSnackbar} from "notistack";
import {Box, Typography, useTheme} from "@mui/material";
import dayjs from "dayjs";

function Anons() {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar()
    const {loaded, error, data} = AxiosGet(apiUrl + "/event/findAllForCurrentDate", "GET", null, enqueueSnackbar);
    console.log("Anons");

        if (error) {
            return (
                <div className="content-wrappers">
                    <TitleRow title="Ближайшее мероприятие"/>
                    <EventRow time="" title="" error="Данные временно недоступны"/>
                </div>
            )
        } else if (loaded === false) {
            return <div className="content-wrappers">
                        <TitleRow title="Ближайшее мероприятие"/>
                        <div className="anons">
                            <div className="anonsImg anim-bg background-param centeredWrapper"
                                 style={{backgroundImage: 'url(""),linear-gradient(90deg, #0000005e, #00000038)'}}>
                            </div>
                        </div>
                    </div>;
        } else {

            const targetTime = new Date();
            targetTime.setHours(targetTime.getHours() + 1);

            const sortedEvents = data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
            let nextEvent = sortedEvents.filter(e => new Date(e.startTime) > targetTime)[0];
            if(!nextEvent)
                nextEvent = sortedEvents[0]

            return (
                <div className="content-wrappers">
                    <TitleRow title="Ближайшее мероприятие"/>
                    <Box className="anons" position={"relative"}>
                        <div className="anonsImg background-param centeredWrapper"
                             style={{backgroundImage: 'url("' + staticPath + nextEvent?.mediaFile?.url + '"),linear-gradient(90deg, #0000005e, #00000038)'}}>
                            {nextEvent?.time}
                        </div>
                        <Box width={"100%"} height={"100%"} className="flex-col-vcenter-hcenter absolute-position">
                            <Typography variant="hLarge" textAlign={"center"} color={theme.palette.text.contrast}>{nextEvent?.title}</Typography>
                            <Box width={"100%"} className="flex-col-vcenter-hcenter">
                                <Typography variant="hLarge" textAlign={"center"} color={theme.palette.text.contrast}>{nextEvent ? formatDate(nextEvent?.startTime) : ""}</Typography>
                            </Box>
                        </Box>

                    </Box>
                </div>
            );
        }

}

function formatDate(dateString) {
    const date = dayjs(dateString);
    return date.format('HH:mm');
}



export default Anons;
