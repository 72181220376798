import Splitter from "../component/Splitter";
import DailyPresentItem from "../component/DailyPresentItem";
import React, {useContext, useState} from "react";
import {
    DailyPresentViewContext,
    NotifyModalContext,
    TaskParamContext,
    TokenContext
} from "../Contexts";
import {notifyModalStateParam} from "../commonFunction/Constants";
import {FetchQuery} from "../commonFunction/FetchQuery";
import notifyModalStateService from "../commonFunction/NotifyModalStateService";
import {apiUrl} from "../schema/Environment";

function DailyPresentView(){

    const [dailyPresentViewActive, setDailyPresentViewActive] = useContext(DailyPresentViewContext);
    const [currentToken, setToken] = useContext(TokenContext);
    const {setTaskParam} = useContext(TaskParamContext);
    const [setNotifyModalState] = useContext(NotifyModalContext);
    const [rotateValue, setRotateValue] = useState(0);

    function startRotateCircle() {
        var randomInt = randomInteger(800, 1600);
        var part = 360 - randomInt % 360;

        setRotateValue(randomInt);


        setTimeout(function () {
            showPresentTask(part);
        }, 7000);
    }

    function showPresentTask(part) {

        var taskGroup;

        if (part >= 0 && part < 45)
            taskGroup = 'STUDENT';
        if (part >= 45 && part < 90)
            taskGroup = 'EATABLE';
        if (part >= 90 && part < 135)
            taskGroup = 'ORG';
        if (part >= 135 && part < 180)
            taskGroup = 'TECH_FAILURE';
        if (part >= 180 && part < 225)
            taskGroup = 'TECH_COINS';
        if (part >= 225 && part < 270)
            taskGroup = 'STUDENT';
        if (part >= 270 && part < 315)
            taskGroup = 'SPORT';
        if (part >= 315 && part < 360)
            taskGroup = 'ORG';

        // FIXME ТУТ НАДО ПОКАЗАТЬ ПРЕЛОАД


        FetchQuery(apiUrl + "/task/generatePresentTask?token=" + currentToken + "&taskGroupType=" + taskGroup)
            .then((fetchResult) => {
                if(fetchResult?.hasError === true)
                    setNotifyModalState(notifyModalStateService('ERROR',fetchResult?.text));
                else{
                    var item = fetchResult?.body;
                    setTaskParam(
                        {
                            active: true,
                            id: item.id,
                            title: item.title,
                            description: item.description,
                            conditions: item.conditions,
                            cost: item.cost,
                            taskAIMType: item.taskAIMType,
                            taskGroupName: item.taskGroupName,
                            taskGroupType: item.taskGroupType,
                            taskDurability: item.taskDurability,
                            image: item.image,
                            buyCount: item.buyCount,
                            ownerUser: item.ownerUser,
                            aimUser: item.aimUser,
                            taskUseStatus: item.taskUseStatus,
                            duelResult: item.duelResult
                        });
                    // тут у нас есть props который хранит все параметры для отображения task
                    setDailyPresentViewActive(false);
                }
            });

    }

    function randomInteger(min, max) {
        var rand = min - 0.5 + Math.random() * (max - min + 1);
        rand = Math.round(rand);
        if (rand % 45 == 0)
            rand = rand + 10;

        return rand;

    }

    return (
        <div className={dailyPresentViewActive ? 'task-data-wrapper active' : 'task-data-wrapper'}>
            <div className="task-view page">
                <div>
                    <div className="header-inner daily-present-modal">
                        SOLBAUMANEC
                        <div  onClick={e => setDailyPresentViewActive(false)} className="abs-right-icon header-right-icon">
                            <img className="right-icon-img" src="/img/close_white.png"/></div>
                    </div>
                    <div className="top-navigation">
                        <div className="title-row">
                            Испытай свою удачу!
                            <div className="abs-right-icon-row"><img className="right-icon-img" src="/img/info_white.png"/></div>
                        </div>
                    </div>
                </div>


                <Splitter/>
                <div className="content-wrappers p-t-1" >
                    <div className="centeredWrapper m-1">
                        <div className="icon-wrapper centeredWrapper" style={{backgroundImage: 'url(/img/down-arrow.png)', height: '10rem'}}></div>
                    </div>
                    <div className="centeredWrapper">
                        <div className="circle" style={{backgroundImage: 'url(/img/circle_simple.jpg)', transform: 'rotate(' + rotateValue + 'deg)'}} ></div>
                    </div>
                    <div className="start-rotate-circle-wrapper centeredWrapper">
                        <div className="start-rotate-circle" onClick={startRotateCircle}>
                            Вращать
                        </div>
                    </div>
                </div>

                <Splitter/>

                <div className="content-wrappers">
                    <div className="circle-description-wrapper">
                        <DailyPresentItem icon="/img/org_white.png" description="Вы получите одно из заданий связанное с организаторами"/>
                        <DailyPresentItem icon="/img/present_white.png" description="Забери один из возможных подарков, если повезет"/>
                        <DailyPresentItem icon="/img/student_white.png" description="Вы получите одно из заданий связанное с студентами"/>
                        <DailyPresentItem icon="/img/duel_white.png" description="Задание - дуэль, потратьте его, выбрав свою цель"/>
                        <DailyPresentItem icon="/img/code_white.png" description="Случайное число баллов"/>
                        <DailyPresentItem icon="/img/warning.png" description="Если выпадет данный слот, задание придется выполнять вам"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DailyPresentView;
