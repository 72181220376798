import React from "react";

export const AuthModalContext = React.createContext();
export const TokenContext = React.createContext();
export const NotifyModalContext = React.createContext();
export const TaskParamContext = React.createContext();
export const GameParamContext = React.createContext();
export const CurrentStudentContext = React.createContext(undefined);
export const PreloaderContext = React.createContext(false);

export const DailyPresentViewContext = React.createContext();

