import '../../css/custom.css';
import '../../css/neighbor.css';
import EventRow from "./EventRow";
import React, {useContext, useState} from "react";
import {GameParamContext, GameViewContext, TaskParamContext} from "../Contexts";
import logo_student from "../../img/student_white.png";
import logo_org from "../../img/org_white.png";
import logo_duel from "../../img/duel_white.png";
import logo_present from "../../img/present_white.png";
import logo_info from "../../img/info_white.png";

import {staticPath} from "../schema/Environment";
import {Box, Button, Typography} from "@mui/material";
import {useTheme} from "@mui/system";
// import Modal from "react-awesome-modal";

function TaskCard(props){

    console.log("TaskCard");
    const theme = useTheme();
    const {setTaskParam} = useContext(TaskParamContext);
    const {setGameParam} = useContext(GameParamContext);

    const typeSet = [
        {key: 'STUDENT', name: 'Друзьям', link: '/neighbor/student', logo: logo_student},
        {key: 'ORG', name: 'Оргам', link: '/neighbor/org', logo: logo_org},
        {key: 'SPORT', name: 'Спорт', link: '/neighbor/duel', logo: logo_duel},
        {key: 'EATABLE', name: 'Ништячки', link: '/neighbor/present', logo: logo_present},
        // {key: 'CHALLENGE', name: 'Челенджи', link: '/neighbor/present', logo: logo_present},
        {key: 'INFO', name: 'Инфо', link: '/neighbor/present', logo: logo_info}
    ];

    const currentType = typeSet.filter(t => t.key === props.taskGroupType)[0];


    // ДИНАМИЧЕСКИЕ ЭЛЕМЕНТЫ

        let costElement = <div></div>;
        if(props.cost !== undefined){
            costElement =
                <Box className={"task-cost"} sx={{position: 'absolute', top: '1rem', right: '1rem'}}>
                    <div className="percent" style={{width: "fit-content"}}>{props.cost} Coins</div>
                </Box>
        }

        let browseElement = <div></div>;
        if(props.browse === true){
            browseElement = <Button variant="contained" sx={{width: '100%', marginTop: 'auto', height: '10rem', borderRadius: '10rem', backgroundColor: theme.palette.info.dark}} onClick={e => {}}>
                <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.contrast}>
                    Посмотреть
                </Typography>
            </Button>;
        }

        let durationElement = <div></div>;
        if(props.browse === true){
            if(props.useTime && props.useTime === true)
                durationElement =
                    <Box className={"task-duration"} sx={{position: 'absolute', top: '1.5rem', right: '2rem'}}>
                        <div className="time" style={{width: "fit-content", fontSize: '4rem'}}>{formatTime(props.duration)}</div>
                    </Box>
            else
                durationElement =
                    <Box className={"task-duration"} sx={{position: 'absolute', top: '1.5rem', right: '2rem'}}>
                        <div className="time" style={{width: "fit-content"}}>РАЗОВОЕ</div>
                    </Box>
        }

    let withTargetUserElement = <></>;
    // if(props.browse === true){
    //     if(props.withTargetUser && props.withTargetUser === true)
    //         withTargetUserElement = <div className="with-target-user" style={{marginTop: "1rem", width: "fit-content"}}>С выбором игрока</div>;
    // }

        let appendClass = "";
        if (props.appendClass !== undefined)
            appendClass = props.appendClass;


        let taskCostAndDurationElem = "";
        if(props.taskUseStatus === undefined){
            taskCostAndDurationElem = <Box className="product-list__action">
                <Box className="flex-col">
                    {costElement}
                    {durationElement}
                </Box>
                <Box>
                    {withTargetUserElement}
                </Box>
                </Box>
        }

        function openView(){
            if(props.browse === true) {
                console.log("need open")
                if (props.isGame === undefined) {
                    setTaskParam(
                        {
                            id: props.id,
                            title: props.title,
                            description: props.description,
                            conditions: props.conditions,
                            cost: props.cost,
                            withTargetUser: props.withTargetUser,
                            taskGroupName: props.taskGroupName,
                            taskGroupType: props.taskGroupType,
                            duration: props.duration,
                            mediaFile: props.mediaFile,
                            buyCount: props.buyCount,
                            ownerUser: props.ownerUser,
                            aimUser: props.aimUser,
                            taskUseStatus: props.taskUseStatus,
                            useTime: props.useTime,
                            duelResult: props.duelResult
                        });
                } else {
                    setGameParam(
                        {
                            title: props.title,
                            description: props.description,
                            conditions: props.conditions,
                            image: props.image
                        });
                }
            }
        }

        if (props.error !== undefined)
            return (
                ""
            )
        else if (props.preload === undefined)
            return (
                <Box sx={{bgcolor: theme.palette.grey[200], width: "100%", height: "65rem", boxShadow: '0 0.4rem 0.8rem rgba(0, 0, 0, 0.2)'}} className={"br-4"}  onClick={openView}>
                    <Box sx={{height: '60rem', width: '-webkit-fill-available', position: 'relative'}}>
                        <Box className={"background-param " + appendClass} sx={{width: '-webkit-fill-available', borderRadius: '4rem 4rem 0 0', position: 'relative', height: '30rem', backgroundImage: 'url(' + staticPath + props.mediaFile?.url + '),linear-gradient(90deg, rgb(0 0 0 / 40%), rgb(0 0 0 / 40%))'}}>
                            {durationElement}
                            <Box sx={{position: 'absolute', bottom: '1rem', left: '2rem'}}>
                                <Typography variant="h2" sx={{overflow: "hidden"}} textAlign={"left"} color={theme.palette.text.contrast}>
                                    {props.cost} SOL
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{padding: '2rem'}}>
                            <Typography variant="h3" sx={{overflow: "hidden", textWrap: 'nowrap'}} textAlign={"left"} color={theme.palette.text.primary}>
                                {props.title}
                            </Typography>
                        </Box>
                        <Box sx={{padding: '0rem 2rem 2rem 2rem', height: "20rem", overflow: 'auto'}}>
                            <Typography variant="h4" sx={{overflow: "hidden"}} textAlign={"left"} color={theme.palette.text.secondary}>
                                {props.description}
                            </Typography>
                        </Box>

                    </Box>

                </Box>

                // <div className={"product-list__item background-param " + appendClass}
                //      style={{backgroundImage: 'url(' + staticPath + props.mediaFile?.url + '),linear-gradient(90deg, rgb(0 0 0 / 40%), rgb(0 0 0 / 40%))'}}>
                //     <div className="product-list__link" onClick={openView}>
                //         <div className="product-list__name">
                //             <div className="product-list__logo">
                //                 <img className="task-image-inner" src={currentType?.logo} alt=""/>
                //             </div>
                //             <Typography variant="h2" sx={{overflow: "hidden"}} textAlign={"left"} color={theme.palette.text.contrast}>
                //                 {props.title}
                //             </Typography>
                //         </div>
                //         {taskCostAndDurationElem}
                //         <div className="task-description view-card">{props.description}</div>
                //         <div className="btn-wrapper  centeredWrapper">
                //             {browseElement}
                //         </div>
                //     </div>
                // </div>
            )
        else {
            return (
                <div className={"product-list__item background-param anim-bg " + appendClass}>
                    <div className="product-list__link">
                    </div>
                </div>
            );
        }
}

/**
 * Функция для преобразования времени из формата HH:MM:SS в удобный формат HH:MM
 * @param {string} timeString - Строка времени в формате HH:MM:SS
 * @returns {string} - Преобразованная строка времени в формате HH:MM
 */
const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
};


export default TaskCard;
