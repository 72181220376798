import PageRouter from './js/PageRouter';
import React, {useState} from 'react';

import './fonts/RobotoWeb/roboto.css';
import {SnackbarProvider} from "notistack";
import {
    PreloaderContext
} from "./js/Contexts";
import Preloader from "./js/Preloader";

function App() {
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useState(false);
  return (
      <PreloaderContext.Provider value={[preloaderModalIsActive, setPreloaderModalIsActive]}>
          <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'top'}} style={{fontSize: "3rem"}} >
                <PageRouter/>
                <Preloader/>
          </SnackbarProvider>
      </PreloaderContext.Provider>
  );
}


export default App;
