import '../../css/components.css';

function EventRow(props) {
    if(props.error !== undefined)
        return (
            <div className="event-row centeredWrapper">
                <span className="event-row-error">{props.error}</span>
            </div>
        )
    else if(props.preload === undefined)
        return (
            <div className="event-row ">
                <div className="time-event-wrapper border centeredWrapper">
                    <span>{props.time}</span>
                </div>
                <div className="name-event-wrapper border">
                    <div className="name-event">
                        <span>{props.title}</span>
                    </div>
                </div>
            </div>
        )
    else{
        return (
            <div className="event-row anim-bg">
            </div>
        )
    }
}


export default EventRow;
