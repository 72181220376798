import React, {useContext, useRef, useState} from 'react';
import "../../../css/dragAndDrop.css"
import {apiUrl, staticPath} from "../../schema/Environment";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import Grid from "@mui/material/Grid";
import {PreloaderContext} from "../../Contexts";
import {AxiosPostWithoutState} from "../../axios/AxiosPost";
import {useSnackbar} from "notistack";
import {Box, Button, Typography, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/system";


export function UploadedFile(type, path, fileName, id){
    if (path)
        path = staticPath + path
    if(id === undefined)
        id = null;
    return {
        id: id,
        fileExtensionType: type,
        filePath: path,
        fileName: fileName
    };
}


export function MediaUploader ({loadedFile, type, handleChangeFile, disabled = false, targetMediaPath}) {
    const fileInputRef = useRef(null);
    const [drag, setDrag] = useState(false);
    const [uploadedFile, setUploadedFile] = React.useState(loadedFile ? loadedFile : new UploadedFile(type,null));
    const [error, setError] = useState('');
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);
    const {enqueueSnackbar} = useSnackbar();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const theme = useTheme();

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(true);
    }

    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(false);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            sendAndUploadFileToServer(e.dataTransfer.files[0]);
        }
    }


    const handleDownload = (e) => {
        console.log("handleDownload");
        e.preventDefault();
        e.stopPropagation();
        if (uploadedFile.url) {
            const fileUrl = apiUrl + uploadedFile.url;
            const link = document.createElement("a");
            link.href = fileUrl;
            link.target = '_blank';
            link.download = uploadedFile.fileName;
            link.click();
        }
    }

    function handleDelete (e, mediaId) {
        e.preventDefault();
        e.stopPropagation();
        handleChangeFile(mediaId, "DELETE");
        setUploadedFile(new UploadedFile(type, null));
    }

    const handleClick = (e) => {
        console.log("handleClick");
        e.preventDefault();
        e.stopPropagation();
        fileInputRef.current = document.createElement("input");
        fileInputRef.current.type = "file";
        fileInputRef.current.id = "uploadFileInput";
        fileInputRef.current.style.display = "none";
        fileInputRef.current.onchange = (e) => {
            sendAndUploadFileToServer(e.target.files[0]);
        }
        document.body.appendChild(fileInputRef.current);
        fileInputRef.current.click();
    }

    function sendAndUploadFileToServer (file) {
        if ((file.type.startsWith("image") && type === 'IMAGE') ||
            (file.type.startsWith("audio") && type === 'AUDIO') ||
            (file.type.startsWith("video") && type === 'VIDEO')) {

            const formData = new FormData();
            formData.append('file', file);
            setPreloaderModalIsActive(true);

            AxiosPostWithoutState(apiUrl + "/file/upload", {"targetMediaPath": targetMediaPath}, formData).then(({loaded, error, data}) => {
                if(error){
                    enqueueSnackbar(error, {variant: "error"});
                    console.log("Произошла ошибка при сохранении файла: \n" + error);
                }
                else {
                    enqueueSnackbar("Сохранено", {variant: "success"});
                    handleChangeFile(data);
                    setUploadedFile(data);
                }
                setPreloaderModalIsActive(false);
            }).catch(error => {
                enqueueSnackbar("Произошла ошибка при сохранении файла", {variant: "error"});
                console.log("Произошла ошибка при сохранении файла: \n" + error);
                setPreloaderModalIsActive(false);
            });
        } else {
            setError("Недопустимый формат файла для загрузки. Доступный тип файла - " + type)
        }
    }

    let uploadedAreaHeight = 'height-a-20';
    if(type === "IMAGE")
        uploadedAreaHeight = 'height-a-20';
    if(type === "AUDIO")
        uploadedAreaHeight = 'height-a-20';
    if(type === "VIDEO")
        uploadedAreaHeight = 'height-a-20';

    let controlButtons = <></>;
    // if(disabled === false)
    //     controlButtons = <Box marginTop={"auto"} width={"-webkit-fill-available"} marginLeft={"1rem"} marginRight={"1rem"}>
    //         <Grid container justifyContent={!isSmallScreen ? "center" : "center"} spacing={1} sx={{mt: '1rem', mb: '1rem'}} direction="row">
    //             <Grid item xs={6} sm={!isSmallScreen ? "auto" : 12}>
    //                 <Button variant="contained" sx={{ width: '100%', pl: '4rem', pr: '4rem', backgroundColor: theme.palette.error.dark}} onClick={e => handleDelete(e, loadedFile.id)}>
    //                     <Typography variant="button" color={theme.palette.text.contrast}>
    //                         Удалить
    //                     </Typography>
    //                 </Button>
    //             </Grid>
    //             <Grid item xs={6} sm={!isSmallScreen ? "auto" : 12}>
    //                 <Button variant="contained" sx={{ width: '100%', pl: '4rem', pr: '4rem', backgroundColor: theme.palette.success.dark }} color="primary" onClick={handleDownload}>
    //                     <Typography variant="button" color={theme.palette.text.contrast}>
    //                         Скачать
    //                     </Typography>
    //                 </Button>
    //             </Grid>
    //         </Grid>
    //     </Box>


    let filePreview = null;
    if (uploadedFile.filePath) {
        const fileUrl = staticPath + uploadedFile.url;
        if (uploadedFile.fileExtensionType === 'IMAGE' && uploadedFile.filePath) {
            filePreview = <div className = {"preview preview-image " + uploadedAreaHeight} style={{backgroundImage: "url(" +fileUrl + ")"}} alt="preview">{controlButtons}</div>
        }
        if (uploadedFile.fileExtensionType === 'AUDIO' && uploadedFile.filePath) {
            filePreview = <div className = {"flex-col-vcenter-hcenter " + uploadedAreaHeight}><ReactAudioPlayer className="width-p-100" src={fileUrl} controls/>{controlButtons}</div>
        }
        if (uploadedFile.fileExtensionType === 'VIDEO' && uploadedFile.filePath) {
            filePreview = <div className = {"flex-col-vcenter-hcenter " + uploadedAreaHeight}><ReactPlayer url={fileUrl} controls={true} width='100%' height={"fit-content"}/>{controlButtons}</div>
        }
    } else {
        filePreview = (
            <div className={"preview " + uploadedAreaHeight}>
                {/*<FontAwesomeIcon icon="upload"/>*/}
                {error && <p style={{color: "red"}}>{error}</p>}
            </div>
        )
    }

    if(disabled === false)
        return (
            <div className="height-fit-content width-p-100" >
                <div
                    className="drag-drop-container m-b-1"
                    onDragOver={handleDrag}
                    onDragEnter={handleDragIn}
                    onDragLeave={handleDragOut}
                    onDrop={handleDrop}
                    onClick={handleClick}
                >
                    {filePreview}
                </div>
            </div>
        );
    else
        return (
            <div className="height-fit-content width-p-100">
                {filePreview}
            </div>
        );
}

export default MediaUploader;
