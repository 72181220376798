import React, {useContext, useEffect, useReducer, useState} from "react";
import {AuthModalContext, NotifyModalContext, PreloaderContext, TaskParamContext, TokenContext} from "../Contexts";
import '../../css/taskModal.css';
import '../../css/header.css';
import '../../css/custom.css';
import AvatarBox from "../component/AvatarBox";
import {notifyModalStateParam} from "../commonFunction/Constants";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import notifyModalStateService from "../commonFunction/NotifyModalStateService";
import {apiUrl, staticPath} from "../schema/Environment";
import CustomCloseIcon from '../../img/close_white.png';
import {AxiosGet} from "../axios/AxiosGet";
import {useSnackbar} from "notistack";
import {Box, Button, Grid, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useTheme} from "@mui/system";
import {AxiosPostWithoutState} from "../axios/AxiosPost";
import ModalWithContent from "../modal/ModalWithContent";
import SelectTargetUser from "./SelectTargetUser";

function TaskView(props) {

    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const {taskParam, setTaskParam} = useContext(TaskParamContext);
    const [currentToken] = useContext(TokenContext);
    const [modalActive, setModalActive] = useContext(AuthModalContext);
    const [userSearchActive, setUserSearchActive] = useState(false);
    const [targetUser, setTargetUer] = useState(null);
    const [setNotifyModalState] = useContext(NotifyModalContext);
    const duelResultElem = <DuelResult/>
    const [fetchResult, setFetchResult] = useState(InitResponseInitialState());
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);

    const [modalOpen, setModalOpen] = useState(false);

    function selectStudent(student) {
        console.log(student);
        setTargetUer(student);
        setModalOpen(false);
    }

    console.log("TaskView");

    const handleBuy = async e => {
        e.preventDefault();
        //TODO тут надо показать прелоад
        buyTask();
    }

    const handleClose = () => {
      setTaskParam('hide');
    }

    function buyTask() {
        setPreloaderModalIsActive(true);
        AxiosPostWithoutState(apiUrl + "/neighbor/task/buy", {"id": taskParam.id, "targetUserName" : targetUser ? targetUser.username : null}, null, currentToken).then(({
                                                                                                  loaded,
                                                                                                  error,
                                                                                                  data
                                                                                              }) => {
            if (error) {
                enqueueSnackbar(error, {variant: 'error'});
                console.log("Произошла ошибка при сохранении: \n" + error);
            } else {
                enqueueSnackbar("Задание купленно", {variant: 'success'});
            }
            setPreloaderModalIsActive(false);
            setTaskParam('hide');
        })
            .catch((error) => {
                setPreloaderModalIsActive(false);
                enqueueSnackbar("Возникла непредвиденная ошибка, повторите позже", {variant: 'error'});
            });
    }


    let taskAimTypeElem = '';
    if (taskParam.withTargetUser === true) {
        taskAimTypeElem =
            <Box sx={{position: 'relative'}} marginTop={"4rem"} width={"100%"}>
                <Grid container spacing={2} marginRight={"unset"} maxWidth={"100%"}>
                    <Grid item xs={4} className="flex-col-vcenter-hcenter">
                        <AvatarBox avatarUrl={targetUser ? apiUrl + targetUser.avatar.url : undefined} student={targetUser} appendClass="" onClick={e => setModalOpen(true)}/>
                    </Grid>

                    <Grid item xs={8}  className="flex-row-vcenter-hcenter">
                        <Typography variant="body1" textAlign={"left"} color={theme.palette.text.secondary}>
                            Нажми на аватарку, чтобы выбрать игрока.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        if (taskParam.taskUseStatus === 'ACTIVE' || taskParam.taskUseStatus === 'FINISHED')
            taskAimTypeElem =
                <div className="inline-flex p-b-5" style={{position: "relative", marginTop: '4rem'}}>
                    <Box width={"30%"}>
                        <AvatarBox text="Исполнитель" student={taskParam.aimUser} appendClass=""
                                   avatarUrl={apiUrl + taskParam?.aimUser?.avatar?.url}/>
                    </Box>
                    <Box width={"40%"} className="p-l-4 p-r-4 centeredWrapper">
                        <div className="hint-text text-centered">
                            Исполнитель задания обязуется выполнить все условия в соответствии с правилами игры
                        </div>
                    </Box>
                    <Box width={"30%"}>
                        <AvatarBox text="Покупатель" student={taskParam.ownerUser} appendClass=""
                                   avatarUrl={apiUrl + taskParam.ownerUser?.avatar?.url}/>
                    </Box>
                </div>

    }

    let buyElem = '';
    if (taskParam.taskUseStatus === 'NOT_USED') {
        buyElem = <Button variant="contained" sx={{width: '100%', marginTop: '4rem', height: '10rem', borderRadius: '10rem', backgroundColor: theme.palette.info.dark}} onClick={handleBuy}>
            <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.contrast}>
                Использовать
            </Typography>
        </Button>
    } else if (taskParam.taskUseStatus === 'ACTIVE' || taskParam.taskUseStatus === 'FINISHED') {
        buyElem = '';
    }
    else if (!currentToken) {
        buyElem = <div className="width-inherit">
            <div className="user-info-button width-webkit-fill-available">
                <div className="centeredWrapper m-r-2_5">
                    <img className="right-icon-img" src="/img/info.png"/>
                </div>
                <div onClick={e => setModalActive(true)} className="centeredWrapper need-auth-row">
                    Необходимо авторизоваться
                </div>
            </div>
        </div>
    } else {
        buyElem =
        <Button variant="contained" sx={{width: '100%', marginTop: '4rem', height: '10rem', borderRadius: '10rem', backgroundColor: theme.palette.info.dark}} onClick={handleBuy}>
            <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.contrast}>
                Купить
            </Typography>
        </Button>
    }

    let durabilityElem = <div>
        Единовременно
    </div>
    if (taskParam.useTime && taskParam.useTime === true)
        durabilityElem = <div>
            {taskParam.duration}
        </div>

        return (
            <div className={taskParam.active ? 'task-data-wrapper active' : 'task-data-wrapper'}>
                <div className="task-view page" id="buy-task-container">

                    <Box className={"absolute-position"} top={0} zIndex={4} height={"fit-content"}>
                        <Box padding={"4rem"} className={"flex-row-vend"}>
                            <Box  onClick={e => handleClose()}>
                                <FontAwesomeIcon icon={faXmark} style={{color: "#f1f1f1", height: "6rem"}} />
                            </Box>
                        </Box>
                    </Box>
                    <div className="task-image"
                         style={{backgroundImage: 'url(' + staticPath + taskParam.mediaFile?.url + '),linear-gradient(90deg, rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0.22))'}}>
                        <div className="task-data-cover"/>
                    </div>
                    <div>
                        <div className="content-wrappers">
                            <Typography variant="h1" textAlign={"left"} color={theme.palette.text.primary}>{taskParam.title}</Typography>
                            <Box className="inline-wrapper-custom" marginTop={"6rem"} marginBottom={"6rem"}>
                                <Box className="width-inherit" >
                                    <Typography variant="h2" marginBottom={"1rem"} textAlign={"left"} color={theme.palette.grey[400]}>Цена</Typography>
                                    <Typography variant="h2" textAlign={"left"} color={theme.palette.text.primary}>{taskParam.cost} coins</Typography>
                                </Box>
                                <div className="width-inherit">
                                    <Typography variant="h2"  marginBottom={"1rem"} textAlign={"left"} color={theme.palette.grey[400]}>Время действия</Typography>
                                    <Typography variant="h2" textAlign={"left"} color={theme.palette.text.primary}>{durabilityElem}</Typography>
                                </div>
                            </Box>
                            {taskParam.description && taskParam.description.length > 0 ?
                                <>
                                    <Typography variant="h2" marginBottom={"1rem"} textAlign={"left"}
                                                color={theme.palette.grey[400]}>Описание</Typography>

                                    <Typography variant="body1" textAlign={"left"} color={theme.palette.text.primary}>{taskParam.description}</Typography>
                                </>
                                :
                                <></>
                            }
                            {taskAimTypeElem}

                            {buyElem}

                            {taskParam.conditions && taskParam.conditions.length > 0 ?
                                <>
                                    <Typography variant="h2" marginBottom={"1rem"} textAlign={"left"}
                                                color={theme.palette.grey[400]}>Условия</Typography>
                                    <div className="task-text custom p-b-5">{taskParam.conditions}</div>
                                </>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </div>
                {/*<UserSearch/>*/}
                <ModalWithContent content={<SelectTargetUser setTargetStudent={selectStudent}/>} isOpen={modalOpen} onClose={() => setModalOpen(false)}/>
            </div>
        );


    function UserSearch() {
        const {enqueueSnackbar} = useSnackbar()
        const {loaded, error, data} = AxiosGet(apiUrl + "/student/findAllConfirmed", "GET", null, enqueueSnackbar);


        if (fetchResult?.isLoaded === true)
            return (
                <div className={userSearchActive ? 'user-data-wrapper active' : 'user-data-wrapper'}>
                    <div className="header-inner user-search">
                        SOLBAUMANEC
                        <div onClick={e => setUserSearchActive(false)} className="abs-right-icon header-right-icon">
                            <img className="right-icon-img" src="/img/close_white.png"/></div>
                    </div>
                    <div className="task-view page">
                        <div className="search-row-wrapper">
                            <input type="text" className="search-row"/>
                        </div>
                        <div className="content-wrappers p-t-4 margin-unset">
                            {fetchResult?.body?.map(item => (
                                <AvatarBox key={item.id} balance={item.balance} totalBalance={item.totalBalance}
                                           appendClass="inline"/>
                            ))}
                        </div>
                    </div>

                </div>)
        else {
            return (
                <div className={userSearchActive ? 'user-data-wrapper active' : 'user-data-wrapper'}>
                    <div className="header-inner user-search">
                        SOLBAUMANEC
                        <div onClick={e => setUserSearchActive(false)} className="abs-right-icon header-right-icon">
                            <img className="right-icon-img" src="/img/close_white.png"/></div>
                    </div>
                    <div className="task-view page">
                        <div className="search-row-wrapper">
                            <input type="text" className="search-row"/>
                        </div>
                        <div className="content-wrappers p-t-4 margin-unset">
                            <AvatarBox appendClass="inline" preload={true}/>
                            <AvatarBox appendClass="inline" preload={true}/>
                            <AvatarBox appendClass="inline" preload={true}/>
                            <AvatarBox appendClass="inline" preload={true}/>
                        </div>
                    </div>

                </div>
            );
        }
    }
}

function DuelResult(props) {
    return (
        <div>
            <div className="task-sub-text duel-result-controllers m-t-3">Результат дуэли
            </div>

            <div className="duel-result-controllers">
                <div className="hint-text m-t-1 f-s-1_5">Укажите результат
                    дуэли
                </div>
                <div className="width-inherit justifyWrapper inline-wrapper-custom">
                    <div className="user-info-button send-result-duel">Победа</div>
                    <div className="user-info-button send-result-duel m-l-1">
                        Поражение
                    </div>
                </div>
            </div>

            <div
                className="width-inherit justifyWrapper inline-wrapper-custom duel-result-controllers hint-text f-s-1_5">
                Ожидаем результат дуэли...<br/>
                Результат всегда указывает покупатель, после чего вы должны будете подтвердить что согласны
                с результатом
            </div>

            <div
                className="width-inherit justifyWrapper inline-wrapper-custom duel-result-controllers hint-text f-s-1_5">
                Ожидаем подтверждения результата от 2-го игрока...
            </div>
            <div
                className="width-inherit justifyWrapper inline-wrapper-custom duel-result-controllers hint-text f-s-1_5">
                Ожидаем подтверждения результата от 2-го игрока...
            </div>

            <div className="duel-result-controllers">
                <div className="hint-text m-t-1 f-s-1_5">
                    Подтвердите результат<br/><br/>
                    Второй игрок указал что он <strong>победил</strong> в дуэли. <br/>
                    Верно?
                </div>
                <div className="width-inherit justifyWrapper inline-wrapper-custom">
                    <div className="user-info-button send-result-duel">Подтверждаю</div>
                    <div className="user-info-button send-result-duel m-l-1">
                        Не верно
                    </div>
                </div>
            </div>

            <div className="duel-result-controllers">
                <div className="hint-text m-l-1 f-s-1_5">Подтвердите
                    результат<br/><br/>
                    Второй игрок указал что он <strong>проиграл</strong> в дуэли.<br/>
                    Верно?
                </div>
                <div className=" width-inherit justifyWrapper inline-wrapper-custom">
                    <div className="user-info-button send-result-duel">Подтверждаю</div>
                    <div className="user-info-button send-result-duel m-b-1">Не верно
                    </div>
                </div>
            </div>

            <div className="duel-result-controllers hint-text m-b-1 f-s-1_5">
                Дуэль завершена<br/>
                Для вас она закончилась <strong>победой!</strong>
            </div>

            <div className="duel-result-controllers hint-text f-s-1_5 m-b-1">
                Дуэль завершена<br/>
                Для вас она закончилась <strong>поражением(</strong>
            </div>

            <div className="duel-result-controllers hint-text  f-s-1_5 m-b-1">
                Дуэль завершена<br/>
                Для вас она закончилась <strong>поражением(</strong>
            </div>

            <div className="duel-result-controllers hint-text  f-s-1_5 m-b-1">
                Дуэль завершена<br/>
                Для вас она закончилась <strong>победой!(</strong>
            </div>
        </div>
    )
}

export default TaskView;
