import {useTheme} from "@mui/system";
import {useSnackbar} from "notistack";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl} from "../schema/Environment";
import EventRow from "../component/EventRow";
import {Grid} from "@mui/material";
import AvatarBox from "../component/AvatarBox";
import React, {useContext} from "react";
import ModalWithContent from "../modal/ModalWithContent";
import {CurrentStudentContext} from "../Contexts";

export default function SelectTargetUser({setTargetStudent}) {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    const {loaded, error, data} = AxiosGet(apiUrl + "/student/findAllConfirmed", "GET", null, enqueueSnackbar);

    function selectTargetStudent(student) {
        setTargetStudent(student);
    }

    if (error) {
        return (
            <EventRow time="" title="" error="Данные временно недоступны"/>
        )
    } else if (loaded === false) {
        return (
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <div className={"avatar-box anim-bg"}>
                        <div className="avatar-wrapper student-card">
                            <div className="avatar border s-avatar-cover background-param">
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className={"avatar-box anim-bg"}>
                        <div className="avatar-wrapper student-card">
                            <div className="avatar border s-avatar-cover background-param">
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className={"avatar-box anim-bg"}>
                        <div className="avatar-wrapper student-card">
                            <div className="avatar border s-avatar-cover background-param">
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className={"avatar-box anim-bg"}>
                        <div className="avatar-wrapper student-card">
                            <div className="avatar border s-avatar-cover background-param">
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )
    } else {
        console.log("SelectTargetUser")
        if(data.length === 0)
            return (
                <EventRow time="" title="" error="На данный момент ни один игрок не подтвердил участие в игре"/>
            )
        else{
            return (
                <>
                    <Grid container spacing={4}>
                        {data.filter(st => st.username !== currentStudent?.username).map((student, index) => (
                            <Grid key={index} item xs={4}>
                                <AvatarBox avatarUrl={apiUrl + student.avatar.url} fullName={student.fullName} student={student} onClick={selectTargetStudent}/>
                            </Grid>
                        ))}

                    </Grid>
                </>
            )
        }
    }

}
