import '../../css/components.css';
import logo_info from '../../img/info.png';
import {Typography} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/system";

function TitleRow(props) {
    const theme = useTheme();
    return (
        <div className="title-row">
            <Typography variant="h1" textAlign={"left"} color={theme.palette.text.primary}>
                {props.title}
            </Typography>
            <div className="abs-right-icon-row show-hint">
                <img className="right-icon-img" src={logo_info}/>
            </div>
        </div>
    );
}


export default TitleRow;
