import '../../css/custom.css';

function Splitter() {
    return (
        <div className="splitter-horizontal"></div>
    );
}


export default Splitter;
