import '../../css/components.css';
import '../../css/preloader.css';
import React, {useContext, useEffect, useState} from "react";
import DailyPresent from "./DailyPresent";
import {TokenContext} from "../Contexts";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import {apiUrl} from "../schema/Environment";

function BalanceBox(props) {

    const [balance, setBalance] = useState(undefined);
    const [visible, setVisible] = useState('invisible');
    const [currentToken, setToken] = useContext(TokenContext);
    const [fetchResult, setFetchResult] = useState(InitResponseInitialState());


    useEffect(() => {
        if (currentToken)
            FetchQuery(apiUrl + "/student/getByToken?token=" + currentToken)
                .then((fetchResult) => {
                    setFetchResult(fetchResult);
                });
    }, [currentToken]);

    if (fetchResult?.isLoaded && !fetchResult?.hasError) {
        setTimeout(
            function () {
                setVisible('active');
            }, 1000
        );
    }
    if (fetchResult?.isLoaded && !fetchResult?.hasError)
        return (
            <div className={"title-row p-b-1_5 border-b balance-box " + visible}>
                Баланс
                <DailyPresent token={props.token}/>
                <div className="float-r"><span>{fetchResult?.body.balance}</span> coins</div>
            </div>
        )
    else return (
        <div></div>
    );
}


export default BalanceBox;
