import '../../css/components.css';
import '../../css/neighbor.css';
import '../../css/taskModal.css';
import React, {useContext, useEffect, useState} from "react";
import Splitter from "./Splitter";
import DailyPresentItem from "./DailyPresentItem";
import {DailyPresentViewContext, TokenContext} from "../Contexts";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import notifyModalStateService from "../commonFunction/NotifyModalStateService";
import {apiUrl} from "../schema/Environment";

function DailyPresent(props) {
    const [currentToken, setToken] = useContext(TokenContext);

    const [visible, setVisible] = useState('invisible');
    const [dailyPresentViewActive, setDailyPresentViewActive] = useContext(DailyPresentViewContext);
    const [fetchResult, setFetchResult] = useState(InitResponseInitialState());

    useEffect(() => {
        if (currentToken) {
            FetchQuery(apiUrl + "/student/getByToken?token=" + props.token)
                .then((fetchResult) => {
                    setFetchResult(fetchResult);
                    if(!fetchResult?.hasError) {
                        if(fetchResult?.body.achieveDailyPresent === false) {
                            setTimeout(
                                function () {
                                    setVisible('active');
                                }, 1000
                            );
                        }
                    }
                });
        }
    }, [currentToken]);

    function handleClick(e){
        e.preventDefault();
        setVisible('invisible');
        setDailyPresentViewActive(true);
    }
        if(visible !== 'invisible')
            return (

                        <div onClick={handleClick} className="daily-present-wrapper centeredWrapper">
                            <img className="daily-present-img" src="/img/dailyPresent.png"/>
                        </div>
            )
        else return (
            <div className="daily-present-wrapper centeredWrapper">
            </div>
        );
}


export default DailyPresent;
