import '../../css/custom.css';
import React, {useContext, useEffect, useState} from "react";
import AvatarBox from "../component/AvatarBox";
import EventRow from "../component/EventRow";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import {apiUrl} from "../schema/Environment";
import {AxiosGet} from "../axios/AxiosGet";
import {useSnackbar} from "notistack";
import {useTheme} from "@mui/system";
import {TokenContext} from "../Contexts";


function RatingNeighbor() {
    const {enqueueSnackbar} = useSnackbar()
    const theme = useTheme();
    const [currentToken, setToken] = useContext(TokenContext);
    const {loaded, error, data} = AxiosGet(apiUrl + "/student/findAll", "GET", null, enqueueSnackbar, currentToken);



    if (error) {
        return (
            <div className="content-wrappers">
                <EventRow time="" title="" error="Данные временно недоступны"/>
            </div>
        )
    } else if (loaded === false) {
        return (
            <div className="product-list-item-wrapper">
                <AvatarBox appendClass="inline" preload={true}/>
            </div>
        )
    } else {
        return (
            <div className="content-wrappers" style={{paddingTop: '3rem'}}>
                {data.map(student => (
                    <AvatarBox avatarUrl={apiUrl + student.avatar.url} student={student} fullName={student.fullName} key={student.id} balance={student.balance}
                               totalBalance={student.totalBalance} appendClass="inline-percent"/>
                ))}
            </div>
        )
    }
}


export default RatingNeighbor;
