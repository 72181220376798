import '../../css/custom.css';
import '../../css/team_competition.css';
import '../../css/neighbor.css';
import '../../css/components.css';
import React, {useContext, useEffect, useReducer, useState} from "react";
import EventRow from "../component/EventRow";
import TitleRow from "../component/TitleRow";
import TaskCard from "../component/TaskCard";
import Splitter from "../component/Splitter";
import {useSnackbar} from "notistack";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl} from "../schema/Environment";
import {Box, Stack, useTheme} from "@mui/system";
import {makeStyles} from "@mui/styles";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {AuthModalContext, CurrentStudentContext, TokenContext} from "../Contexts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserLock} from "@fortawesome/free-solid-svg-icons";
import {Progress} from "antd";
import UsefulButton from "../component/UsefulButton";
import logo_tg from "../../img/tg_white.png";
import {AxiosPostWithoutState} from "../axios/AxiosPost";
import {TextFieldRow} from "../component/Inputs/TextFields";



export default function Fair({code}) {

    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar()
    const [currentToken, setToken] = useContext(TokenContext);
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    const [modalActive, setModalActive] = useContext(AuthModalContext);
    const [amount, setAmount] = useState(0);
    const [totalWithCommission, setTotalWithCommission] = useState(0);

    let params = null;
    if(code)
        params = {"code": code};
    const {loaded, error, data} = AxiosGet(apiUrl + "/fair/connect", "GET", params, enqueueSnackbar, currentToken);

    const handleAmountChange = (event) => {
        const value = event.target.value;
        setAmount(value);

        const numericValue = parseFloat(value);
        debugger;
        if (!isNaN(numericValue)) {
            const commission = 0.05; // 5% комиссия
            const total = numericValue + numericValue * commission;
            setTotalWithCommission(total);
        } else {
            setTotalWithCommission(0);
        }
    };

    const handleTransfer = async () => {
        const params = {
            "fromFairTeamId": data.fairTeam.id,
            "toFairTeamId": data.fairPoint.fairTeamId,
            "fairPointId": data.fairPoint.id,
            "amount": amount
        };
        AxiosPostWithoutState(apiUrl + "/fair/transaction", params).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar(error, {variant: "error"});
                console.log("Произошла ошибка при сохранении файла: \n" + error);
            }
            else {
                enqueueSnackbar("Перевод выполнен", {variant: "success"});
                // handleChangeFile(data);
                // setUploadedFile(data);
            }
            // setPreloaderModalIsActive(false);
        }).catch(error => {
            enqueueSnackbar("Произошла ошибка при сохранении файла", {variant: "error"});
            console.log("Произошла ошибка при сохранении файла: \n" + error);
            // setPreloaderModalIsActive(false);
        });
    };

    if (error) {
            return (
                <div id="main-content-wrapper">
                    <Splitter/>
                    <div className="content-wrappers" style={{paddingTop: "4rem"}}>
                        <EventRow time="" title="" error="Данные временно недоступны"/>
                    </div>
                </div>
            )
    } else if (loaded === false) {
        return (
            <div id="main-content-wrapper">
                <Splitter/>
                <div className="content-wrappers">
                    <div className="product-list-item-wrapper">
                        <TaskCard preload={true} appendClass="large"/>
                    </div>
                </div>
            </div>
        )
    }
    else if (data) {
            return (
                <div id="main-content-wrapper">
                    <Splitter />
                    <div className="content-wrappers">

                        <TitleRow title={data.fairPoint.name} />

                        <Box marginTop={"4rem"}>
                            <Typography variant="h2" textAlign={"left"} color={theme.palette.text.secondary} style={{ whiteSpace: 'pre-line' }}>
                                {data.fairPoint.description}
                            </Typography>
                        </Box>

                        <Box marginTop={"8rem"} sx={{borderTop: '0.1rem solid grey', paddingTop: '4rem'}} >
                            <Box className={"flex-row-space-between"}>
                                <Typography variant="h2" textAlign={"left"} color={theme.palette.text.primary} style={{ whiteSpace: 'pre-line' }}>
                                    Доступно для перевода:
                                </Typography>

                                <Typography variant="h2" textAlign={"left"} color={theme.palette.text.primary} style={{ whiteSpace: 'pre-line' }}>
                                    {data.fairTeam.balance}
                                </Typography>
                            </Box>
                        </Box>


                        <Box marginTop={"4rem"} height={"10rem"}>
                            <TextFieldRow name={"Сумма"} value={amount} onChangeFunction={handleAmountChange}/>
                        </Box>
                        <Box marginTop={"3rem"} >
                            <Typography variant="h3" marginTop="1rem" color={theme.palette.grey[400]}>
                                Комиссия за перевод - 5%.
                                Итоговая сумма с учетом комиссии: {totalWithCommission.toFixed(2)}
                            </Typography>
                        </Box>
                        <Box>
                            <Button variant="contained" sx={{ mt: "4rem", width: '100%', height: '8rem', pl: '4rem', pr: '4rem', backgroundColor: theme.palette.primary.dark}} onClick={e => handleTransfer()}>
                                <Typography variant="button" color={theme.palette.text.contrast}>
                                    Перевести
                                </Typography>
                            </Button>
                        </Box>


                    </div>
                </div>
            );
    }

}