import React, {useContext, useEffect, useState} from "react";
import OtpInput from "react-otp-input";
import {CurrentStudentContext, NotifyModalContext, PreloaderContext, TokenContext} from "../Contexts";
import {notifyModalStateParam} from "../commonFunction/Constants";
import {FetchQuery, InitResponseInitialState} from "../commonFunction/FetchQuery";
import {setNotifyModalStateWithParam} from "../commonFunction/NotifyModalStateService";
import {apiUrl} from "../schema/Environment";
import {Button, Grid, Typography} from "@mui/material";
import {Box, useTheme} from "@mui/system";
import {AxiosPostWithoutState} from "../axios/AxiosPost";
import {useSnackbar} from "notistack";
import VaultImage from "../../img/vault.jpg";

function CodeInputCard() {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const [OTP, setOTP] = useState("");
    const [currentToken, setToken] = useContext(TokenContext);
    const [notifyModalState, setNotifyModalState] = useContext(NotifyModalContext);
    const [fetchResult, setFetchResult] = useState(InitResponseInitialState());
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);

    function sendCode() {

        console.log("sendCode");
        setPreloaderModalIsActive(true);

        AxiosPostWithoutState(apiUrl + "/code/send", {"code": OTP}, null, currentToken).then(({
                                                                                              loaded,
                                                                                              error,
                                                                                              data
                                                                                          }) => {
            if (error) {
                enqueueSnackbar(error, {variant: 'error'});
                console.log("Произошла ошибка при сохранении: \n" + error);
            } else {
                setOTP('');
                enqueueSnackbar("Баллы зачислены", {variant: 'success'});
            }
            setPreloaderModalIsActive(false);
        })
            .catch((error) => {
                setPreloaderModalIsActive(false);
                enqueueSnackbar("Возникла непредвиденная ошибка, повторите позже", {variant: 'error'});
            });
    }


    return (
        <div className="task background-param " style={{backgroundImage: 'url(' + VaultImage + '),linear-gradient(90deg, #0000005e, #00000038)'}}>
            <div className="task-cover"></div>

            <Box height={"80%"} className="flex-col-vcenter-hcenter">
                <div className="code-input-wrapper">
                    <Grid  container spacing={2}>
                        <Grid marginBottom={"2rem"} item xs={12}>
                            <Typography variant="h1" textAlign={"center"} color={theme.palette.text.contrast}>
                                Ввести код
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="key-input-wrapper centeredWrapper">
                                <div>
                                    <OtpInput
                                        value={OTP}
                                        onChange={setOTP}
                                        autoFocus
                                        numInputs={7}
                                        otpType="number"
                                        disabled={false}
                                        className="key-input flex-row-vcenter-hcenter"
                                        secure
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Box width={"100%"} marginTop={"2rem"} className={"flex-col-vcenter-hcenter"}>
                                <Box width={"50%"}>
                                    <Button variant="contained" sx={{width: '100%', marginTop: 'auto', height: '10rem', borderRadius: '10rem', backgroundColor: theme.palette.info.dark}} onClick={sendCode}>
                                        <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.contrast}>
                                            Отправить
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </Box>

            <Box height={"20%"}>
                <div className="task-footer inline-flex">
                    <div className="balance-container centeredWrapper">
                        <div>
                            <div className="balance-inner"><span>{currentStudent?.balance}</span> Coins</div>
                            <div className="balance-title">Текущий баланс</div>
                        </div>
                    </div>
                    <div className="balance-container centeredWrapper">
                        <div>
                            <div className="balance-inner"><span>{currentStudent?.totalBalance}</span> Coins</div>
                            <div className="balance-title">Получено всего</div>
                        </div>
                    </div>
                    <div className="balance-container centeredWrapper">
                        <div>
                            <div className="balance-inner"><span>{currentStudent?.coinBalance}</span> Co'LTD</div>
                            <div className="balance-title">Аукцион</div>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    );
}

export default CodeInputCard;
