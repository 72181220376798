import '../../css/custom.css';
import '../../css/components.css';
import '../../css/neighbor.css';
import '../../css/login_new.css';
import '../../css/modal.css';
import '../../css/taskModal.css';
import '../../css/notify_modal.css';
import TitleRow from "../component/TitleRow";
import UsefulButton from "../component/UsefulButton";
import Splitter from "../component/Splitter";


import logo_student from '../../img/student_white.png';
import logo_org from '../../img/org_white.png';
import logo_duel from '../../img/duel_white.png';
import logo_present from '../../img/present_white.png';
import TaskListForGroupName from "../class/TaskListForGroupName";
import React, {useContext, useEffect, useState} from "react";

import {Link, NavLink, BrowserRouter as Router, Redirect, Route} from "react-router-dom";
import {CacheRoute, CacheSwitch} from "react-router-cache-route";

import OperationTasks from "../class/OperationTasks";
import RatingNeighbor from "./RatingNeighbor";
import BalanceBox from "../component/BalanceBox";
import {CurrentStudentContext, NotifyModalContext, TokenContext} from "../Contexts";
import Vault from "../neighbor/Vault";
import {Box, Typography} from "@mui/material";
import {useTheme} from "@mui/system";
import {setNotifyModalStateWithParam} from "../commonFunction/NotifyModalStateService";


let notifyViewed = false;

function NeighborSheet() {

    const theme = useTheme();
    const [currentToken, setToken] = useContext(TokenContext);
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);

    const [notifyModalState, setNotifyModalState] = useContext(NotifyModalContext);


    // useEffect(() => {
    //     if(notifyViewed === false)
    //         setTimeout(() => {
    //             setNotifyModalState(setNotifyModalStateWithParam('INFO', "Привет! \n\n Ввод qr кодов и покупка заданий будет доступна с завтрашнего дня, а пока ты можешь искать Sol Coin"));
    //             notifyViewed = true;
    //         }, 2000);
    // });

    return (

            <div>
                    <Router>
                        <div id="main-content-wrapper">
                            <div className="top-navigation">
                                <BalanceBox/>
                                <div className="inline-wrapper-custom m-t-3 overflow-auto">
                                    <NavLink exact to="/neighbor/student" activeClassName="selected"
                                             className="useful-button-inner centeredWrapper neighbor-nav">
                                        <Typography variant="h3" textAlign={"center"} color={theme.palette.text.contrast}>
                                            магазин
                                        </Typography>
                                    </NavLink>
                                    {currentStudent ?
                                        <>
                                            <NavLink exact to="/neighbor/vault"
                                                     className="useful-button-inner centeredWrapper neighbor-nav">
                                                <Typography variant="h3" textAlign={"center"} color={theme.palette.text.contrast}>
                                                    кошелек
                                                </Typography>
                                            </NavLink>
                                            <NavLink exact to="/neighbor/boughtTasks"
                                                     className="useful-button-inner centeredWrapper neighbor-nav">
                                                <Typography variant="h3" textAlign={"center"} color={theme.palette.text.contrast}>
                                                    купленно
                                                </Typography>
                                            </NavLink>
                                            <NavLink exact to="/neighbor/assignedTasks"
                                                     className="useful-button-inner centeredWrapper neighbor-nav">
                                                <Typography variant="h3" textAlign={"center"} color={theme.palette.text.contrast}>
                                                    назначенно
                                                </Typography>
                                            </NavLink>
                                        </>
                                        :
                                        <></>
                                    }
                                    <NavLink exact to="/neighbor/rating"
                                             className="useful-button-inner centeredWrapper neighbor-nav">
                                        <Typography variant="h3" textAlign={"center"} color={theme.palette.text.contrast}>
                                            рейтинг
                                        </Typography>
                                    </NavLink>
                                    <NavLink exact to="/neighbor/newTask"
                                             className="useful-button-inner centeredWrapper neighbor-nav">
                                        <Typography variant="h3" textAlign={"center"} color={theme.palette.text.contrast}>
                                            предложить задание
                                        </Typography>
                                    </NavLink>
                                </div>
                            </div>

                            <CacheSwitch>
                                <CacheRoute path="/neighbor/student">
                                    <TaskCategory/>
                                    <TaskListForGroupName token={currentToken} groupType="STUDENT"/>
                                </CacheRoute>
                                <CacheRoute path="/neighbor/org">
                                    <TaskCategory/>
                                    <TaskListForGroupName token={currentToken} groupType="ORG"/>
                                </CacheRoute>
                                <CacheRoute path="/neighbor/duel">
                                    <TaskCategory/>
                                    <TaskListForGroupName token={currentToken} groupType="SPORT"/>
                                </CacheRoute>
                                <CacheRoute path="/neighbor/present">
                                    <TaskCategory/>
                                    <TaskListForGroupName token={currentToken} groupType="EATABLE"/>
                                </CacheRoute>

                                <Route path="/neighbor/vault">
                                    <Vault/>
                                </Route>
                                <Route path="/neighbor/boughtTasks">
                                    <OperationTasks token={currentToken} taskType="bought"/>
                                </Route>
                                <Route path="/neighbor/assignedTasks">
                                    <OperationTasks token={currentToken} taskType="assigned"/>
                                </Route>
                                <CacheRoute path="/neighbor/rating">
                                    <RatingNeighbor/>
                                </CacheRoute>
                                <CacheRoute path="/neighbor/newTask">
                                    <NewTask/>
                                </CacheRoute>
                            </CacheSwitch>
                        </div>
                    </Router>
            </div>

    )


    function TaskCategory() {

        const typeSet = [
            {key: 'STUDENT', name: 'Друзьям', link: '/neighbor/student', logo: logo_student},
            {key: 'ORG', name: 'Оргам', link: '/neighbor/org', logo: logo_org},
            {key: 'SPORT', name: 'Спорт', link: '/neighbor/duel', logo: logo_duel},
            {key: 'EATABLE', name: 'Ништячки', link: '/neighbor/present', logo: logo_present},
            // {key: 'CHALLENGE', name: 'Челенджи', link: '/neighbor/present', logo: logo_present}
        ];

        return (
            <div>
                <Splitter/>
                <div className="content-wrappers">
                    <TitleRow title="Категории"/>
                    <Box width={"100%"} sx={{overflowY: "auto"}}>
                        <div className="inline-flex">
                            {typeSet.map((item, key) => (
                                <NavLink key={key} exact to={item.link} activeClassName="selected">
                                    <UsefulButton title={item.name} image={item.logo}/>
                                </NavLink>
                                ))
                            }
                        </div>
                    </Box>
                </div>
            </div>
        )
    }

    function NewTask() {
        return (
            <div>
                <Splitter/>
                <div className="content-wrappers">
                    <TitleRow title="Предложить задание"/>
                    <div className="inline-flex">
                        <div>
                            <div className="hint-text small new-task">
                                Что ж, мы знаем о желании многих придумать свое задание в игре.<br/>
                                <br/>
                                    Ты в числе этих людей? Тогда это твой шанс!<br/>
                                    <br/>
                                        Все предельно просто: придумай название, описание и цену, реши с исполняющим
                                        игроком или нет и отправь нам.<br/>
                                        Если оно того стоит, задание появится в игре в ближайшее время.<br/>
                                        А вообще не надо стеснятся нам интересны любые идеи!

                                        <div className="task-sub-text m-t-2">Название</div>
                                        <div className="width-inherit;">
                                            <input id="new-task-title" type="text" className="user-info-button width-webkit-fill-available"/>
                                        </div>
                                        <div className="task-sub-text m-t-1">Цена
                                        </div>
                                        <div className="m-t-1 width-inherit">
                                            <input  id="new-task-cost" type="text" className="user-info-button width-webkit-fill-available"/>
                                        </div>
                                        <div className="task-sub-text m-t-1">Описание
                                        </div>
                                        <div className="width-inherit m-t-1">
                                            <textarea className="user-info-button width-webkit-fill-available new-task-description"></textarea>
                                        </div>

                                        <div className="inline-flex m-t-3">
                                            <div>
                                                <div className="f-s-4_5" >С исполняющим игроком?</div>
                                                <div className="f-s-3 color-grey">Если, да то задание обязательно
                                                    нужно назначать любому другому игроку
                                                </div>
                                            </div>
                                            <div className="centeredWrapper m-l-4">
                                                <input className="new-task-with-performed" type="checkbox"/>
                                            </div>
                                        </div>
                                        <div className="centeredWrapper width-inherit ">
                                            <div className="user-info-button m-t-3" id="send-new-task">Отправить
                                            </div>
                                        </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default NeighborSheet;
