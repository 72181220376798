import * as React from 'react';
import {TextField} from "@material-ui/core";
import { styled } from '@mui/material/styles';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Grid} from "@mui/material";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider, DateTimePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { TimePicker } from 'antd';
import { Dayjs } from 'dayjs';


const SolTextField = styled(TextField)(({ theme }) => ({
    '&.MuiTextField-root': {
        width: '100%',
        height: '100%',
        '.MuiFormLabel-root': {
            fontSize: theme.typography.h3.fontSize,
        },
        '.MuiInputBase-input': {
            fontSize: theme.typography.h3.fontSize,
            padding: "1.5rem 1.5rem",
        },
        '&.MuiFormControl-root':{
            '.MuiInputBase-root': {
                fontSize: theme.typography.h3.fontSize,
                width: "inherit",
                height: "inherit",
                'input': {
                    textAlign: "left"
                }
            },
            '.MuiInputLabel-outlined': {
                // transform: "translate(1.5rem, 1.5rem) scale(1)",
            }
        }
    },
}));

const TextFieldRectangle = styled(TextField)(({ theme }) => ({
    '&.MuiTextField-root': {
        width: "100%",
        height: "100%",
        '.MuiFormLabel-root': {
            fontSize: '1.6rem',
        },
        '&.MuiFormControl-root':{
            '.MuiInputBase-root': {
                fontSize: '1.8rem',
                width: "inherit",
                height: "inherit",
                'input': {
                    textAlign: "center",
                    maxWidth: "2rem"
                }
            },
            '.MuiInputBase-input': {
                maxWidth: "2rem"
            }
        }
    },
}));

const TextFieldStyledFullHeight = styled(TextField)(({ theme }) => ({
    lineHeight: '120%',
    '&.MuiTextField-root': {
        height: '100%',
        width: '100%',
        lineHeight: '120%',
        '.MuiInputBase-root': {
            height: '100%',
            lineHeight: '120%',
        },
        '.MuiFormLabel-root': {
            fontSize: '1.6rem',
        },
        '.MuiInputBase-input': {
            lineHeight: '120%',
            fontSize: '1.5rem',
        }
    },
}));


export function TextFieldRow({name, onChangeFunction, value, disabled = false}) {
    return <SolTextField variant="outlined" label={name} onChange={onChangeFunction} value={value} disabled={disabled} />;
}

export function TextFieldRowWithoutLabel({name, onChangeFunction, value, disabled = false}) {
    return <SolTextField variant="outlined" onChange={onChangeFunction} value={value} disabled={disabled}/>;
}

export function TextFieldAsRectangle({name, onChangeFunction, value, disabled = false}) {
    return <TextFieldRectangle variant="outlined" label={name} onChange={onChangeFunction} value={value} disabled={disabled} />;
}

export function TextFieldMultipleRows({name, onChangeFunction, value, disabled = false}) {
    return <TextFieldStyledFullHeight multiline
                                      minRows={4}
                                      wrap="true" disabled={disabled}  variant="outlined" label={name} onChange={onChangeFunction} value={value} />;
}

export function InputWithCalendar({title, paramHandleChange, value}){
    const [date, setDate] = React.useState(formatDate(value));


    const handleChange = (newValue) => {
        setDate(formatDate(newValue));

        if(paramHandleChange){
            paramHandleChange(formatDate(newValue));
        }
    };

    const renderInput = (calendarParams) => {
        return (
            <SolTextField variant="outlined" label="Дата" {...calendarParams}/>
        );
    };

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            label={title}
            value={date}
            onChange={handleChange}
            renderInput={renderInput}
            inputFormat="YYYY-MM-DD"
            format="YYYY-MM-DD"
        />
    </LocalizationProvider>
}



function formatDate(dateString) {
    const date = dayjs(dateString);
    return date.format('YYYY-MM-DD HH:mm:ss');
}

export function DateTimeInput({title, paramHandleChange, value}){

    const [date, setDate] = React.useState(formatDate(value));
    const handleChange = (newValue) => {
        setDate(formatDate(newValue));

        if(paramHandleChange){
            paramHandleChange(formatDate(newValue));
        }
    };

    const renderInput = (calendarParams) => {
        return (
            <SolTextField label="Outlined" variant="outlined" label="Дата" {...calendarParams}/>
        );
    };

    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                label={title}
                value={date}
                onChange={handleChange}
                renderInput={renderInput}
                inputFormat="YYYY-MM-DD HH:mm:ss"
                format="YYYY-MM-DD HH:mm:ss"
             />
        </LocalizationProvider>
    )
}

export function TimeInput({onChangeFunction, value, disabled = false }) {

    if(value === "")
        value = "00:00:00";

    const onChange = (time: Dayjs, timeString: string) => {
        onChangeFunction(timeString);
    };

    return (
        <TimePicker disabled={disabled} style={{ height: '100%', width: '100%' }} onChange={onChange} value={dayjs(value, 'HH:mm:ss')} defaultValue={dayjs('00:00:00', 'HH:mm:ss')} />
    );
}

