import '../../css/components.css';
import '../../css/preloader.css';
import React from "react";
import {Typography} from "@mui/material";
import {useTheme} from "@mui/system";

function AvatarBox({avatarUrl = '/img/human.png', appendClass = "", balance, totalBalance, text, fullName, preload, onClick, student}) {

    const theme = useTheme();

    if(avatarUrl)
        console.log("AvatarBox")
    let balanceElement = "";
    if(balance !== undefined && totalBalance !== undefined)
        balanceElement = <div className="avatar-text-wrapper-top">
                            <Typography variant="h5" sx={{overflow: "hidden"}} textAlign={"left"} color={theme.palette.text.contrast}>
                                Всего {totalBalance}
                            </Typography>
                            <Typography variant="h5" sx={{overflow: "hidden"}} textAlign={"left"} color={theme.palette.text.contrast}>
                                Сейчас {balance}
                            </Typography>
                            {/*<div className="avatar-text balance-text">Всего {totalBalance}<br/>Сейчас {balance}</div>*/}
                         </div>;

    if(text)
        balanceElement =
            <div className="avatar-text-wrapper-top">
                <Typography variant="h6" sx={{overflow: "hidden"}} textAlign={"center"} color={theme.palette.text.contrast}>
                    {text}
                </Typography>
            </div>;

    let fullNameElem = "";
    if(student)
        fullNameElem =
            <div className="avatar-text-wrapper">
                <Typography variant="h4" sx={{overflow: "hidden"}} textAlign={"left"} color={theme.palette.text.contrast}>
                    {student.fullName}
                </Typography>
            </div>;

    if(!preload) {

        return (
            <div className={"avatar-box " + appendClass} onClick={e => onClick ? onClick(student) : {}}>
                <div className="avatar-wrapper">
                    <div className="avatar border s-avatar-cover background-param"
                         style={{backgroundImage: 'url(' + avatarUrl + '), linear-gradient(180deg, rgb(0 0 0 / 44%), rgb(0 0 0 / 62%))'}}>
                        {fullNameElem}
                        {balanceElement}
                    </div>
                </div>
            </div>
        )
    }
    else{
        return (
            <div className={"avatar-box " + appendClass + " anim-bg"}>
                <div className="avatar-wrapper student-card">
                    <div className="avatar border s-avatar-cover background-param">
                    </div>
                </div>
            </div>
        )
    }
}


export default AvatarBox;
