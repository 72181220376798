const typography = {
  fontFamily: "'Plus Jakarta Sans', sans-serif;",
  hLarge: {
    fontWeight: 600,
    fontSize: '6rem',
    lineHeight: '120%',
    fontFamily: "'Plus Jakarta Sans', sans-serif;",
  },
  h1: {
    fontWeight: 600,
    fontSize: '5rem',
    lineHeight: '120%',
    fontFamily: "'Plus Jakarta Sans', sans-serif;",
  },
  h2: {
    fontWeight: 600,
    fontSize: '4.3rem',
    lineHeight: '120%',
    fontFamily: "'Plus Jakarta Sans', sans-serif;",
  },
  h3: {
    fontWeight: 600,
    fontSize: '4rem',
    lineHeight: '120%',
    fontFamily: "'Plus Jakarta Sans', sans-serif;",
  },
  h4: {
    fontWeight: 600,
    fontSize: '3.8rem',
    lineHeight: '120%',
    fontFamily: "'Plus Jakarta Sans', sans-serif;",
  },
  h5: {
    fontWeight: 600,
    fontSize: '3.2rem',
    lineHeight: '120%',
    fontFamily: "'Plus Jakarta Sans', sans-serif;",
  },
  h6: {
    fontWeight: 600,
    fontSize: '3rem',
    lineHeight: '120%',
    fontFamily: "'Plus Jakarta Sans', sans-serif;",
  },
  button: {
    fontSize: '4rem',
    lineHeight: '100%',
    textTransform: 'capitalize',
    fontWeight: 600,
  },
  bodyExtraLarge: {
    fontSize: '6rem',
    fontWeight: 400,
    lineHeight: '120%',
  },
  bodyLarge: {
    fontSize: '5rem',
    fontWeight: 400,
    lineHeight: '120%',
  },
  body1: {
    fontSize: '4.5rem',
    fontWeight: 500,
    lineHeight: '120%',
  },
  body2: {
    fontSize: '4rem',
    fontWeight: 400,
    lineHeight: '120%',
  },
  body3: {
    fontSize: '3.5rem',
    fontWeight: 400,
    lineHeight: '120%',
  },
  body3_semi: {
    fontSize: '3.5rem',
    fontWeight: 500,
    lineHeight: '120%',
  },
  body4: {
    fontSize: '3rem',
    fontWeight: 400,
    lineHeight: '120%',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: '0.7rem',
    fontWeight: 500,
    color: '#595959'
  },
  uptitle1: {
    fontSize: '1.3rem',
    fontWeight: 400,
  },
  uptitle2: {
    fontSize: '0.9rem',
    fontWeight: 500,
    color: '#595959'
  },
  uptitle3: {
    fontSize: '0.8rem',
    fontWeight: 500,
    color: '#595959'
  },
  uptitle4: {
    fontSize: '0.7rem',
    fontWeight: 500,
    color: '#595959'
  },
  uptitleBold1: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  uptitleBold2: {
    fontSize: '0.9rem',
    fontWeight: 600,
    color: '#595959'
  },
  uptitleBold3: {
    fontSize: '0.8rem',
    fontWeight: 600,
    color: '#595959'
  },
  uptitleBold4: {
    fontSize: '0.7rem',
    fontWeight: 600,
    color: '#595959'
  },

};

export default typography;
