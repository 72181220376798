import {AuthModalContext, CurrentStudentContext, PreloaderContext, TokenContext} from "../Contexts";
import Splitter from "../component/Splitter";
import TitleRow from "../component/TitleRow";
import AvatarBox from "../component/AvatarBox";
import OperationTasks from "../class/OperationTasks";
import React, {useContext, useEffect, useState} from "react";
import CodeInputCard from "./CodeInputCard";
import {Box, useTheme} from "@mui/system";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserLock, faXmark} from "@fortawesome/free-solid-svg-icons";
import {Button, Grid, Typography} from "@mui/material";
import ModalWithContent from "../modal/ModalWithContent";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl} from "../schema/Environment";
import {useSnackbar} from "notistack";
import EventRow from "../component/EventRow";
import SelectTargetUser from "./SelectTargetUser";
import CoinBankImage from "../../img/coin_bank.jpg";
import {AxiosPostWithoutState} from "../axios/AxiosPost";

function Vault({type, code}) {
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const [modalActive, setModalActive] = useContext(AuthModalContext);
    const [currentToken, setToken] = useContext(TokenContext);
    const [currentStudent, setCurrentStudent] = useContext(CurrentStudentContext);
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);
    const [questionCodeViewed, setQuestionCodeViewed] = useState(false);

    const [studentForSendCoins, setStudentForSendCoins] = useState(undefined);
    const [coinsCount, setCoinsCount] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    function selectStudent(student) {
        console.log(student);
        setStudentForSendCoins(student);
        setModalOpen(false);
    }

    function sendCoins() {
        if(!studentForSendCoins){
            enqueueSnackbar("Выберите игрока, которому хотите переслать coins", {variant: 'error'});
            return;
        }
        setPreloaderModalIsActive(true);
        AxiosPostWithoutState(apiUrl + "/coin/send", {"targetUsername": studentForSendCoins.username, "count" : coinsCount}, null, currentToken).then(({
                                                                                                                                                                             loaded,
                                                                                                                                                                             error,
                                                                                                                                                                             data
                                                                                                                                                                         }) => {
            if (error) {
                enqueueSnackbar(error, {variant: 'error'});
                console.log("Произошла ошибка при выполнении перевода: \n" + error);
            } else {
                enqueueSnackbar("Перевод успешно выполнен", {variant: 'success'});
            }
            setPreloaderModalIsActive(false);
            setCoinsCount("");
            setStudentForSendCoins();
        })
            .catch((error) => {
                setPreloaderModalIsActive(false);
                enqueueSnackbar("Возникла непредвиденная ошибка, повторите позже", {variant: 'error'});
            });
    }

    useEffect(() => {
        if(currentStudent && type && code){
            if(type === 'long'){
                setPreloaderModalIsActive(true);
                AxiosPostWithoutState(apiUrl + "/longCode/activate/" + code, null, null, currentToken).then(({
                                                                                                      loaded,
                                                                                                      error,
                                                                                                      data
                                                                                                  }) => {
                    if (error) {
                        enqueueSnackbar(error, {variant: 'error'});
                    } else {
                        enqueueSnackbar("Код принят, на твой счет зачислено " + parseInt(parseInt(data.cost) - parseInt(data.usedCount)) + " Sol Coin", {variant: 'success'});
                    }
                    setPreloaderModalIsActive(false);
                })
                    .catch((error) => {
                        enqueueSnackbar("Возникла непредвиденная ошибка, повторите позже", {variant: 'error'});
                        setPreloaderModalIsActive(false);
                    });
            }
            if(type === 'question'){
                setQuestionCodeViewed(true);
            }
        }
    }, [currentStudent])

    console.log("Vault")
    if (!currentToken || !currentStudent) {
        return (
            <div>
                    <Splitter/>
                    <div  onClick={e => setModalActive(true)} className="content-wrappers p-t-4">

                        <Box className="flex-row-vcenter-hcenter">
                            <FontAwesomeIcon icon={faUserLock} style={{color: theme.palette.info.dark, height: "10rem"}} />
                        </Box>
                        <Box className="flex-row-vcenter-hcenter" marginTop={"2rem"}>
                            <Typography variant="h2" textAlign={"center"} color={theme.palette.text.primary}>
                                Войдите чтобы продолжить
                            </Typography>
                        </Box>
                    </div>
            </div>
        )
    }else {
        return (
            <div>
                <Splitter/>
                <div className="content-wrappers p-t-4">
                    <CodeInputCard/>
                </div>
                <Splitter/>
                <div className="content-wrappers">
                    <TitleRow title="Бонусные задания"/>
                    <OperationTasks token={currentToken} taskType="bonus"/>
                </div>
                <Splitter/>
                <div className="content-wrappers">
                    <TitleRow title="Coin Transfer"/>
                    <Box sx={{position: 'relative'}} width={"100%"}>
                        <Grid container spacing={2} marginRight={"unset"} maxWidth={"100%"}>
                            <Grid item xs={4} className="flex-col-vcenter-hcenter">
                                {/*<AvatarBox avatarUrl={studentForSendCoins ? apiUrl + studentForSendCoins.avatar.url : undefined} appendClass="" onClick={e => setModalOpen(true)}/>*/}
                                {studentForSendCoins ?
                                    <AvatarBox avatarUrl={studentForSendCoins ? apiUrl + studentForSendCoins.avatar.url : undefined} student={studentForSendCoins} fullName={studentForSendCoins.fullName} appendClass=""/>
                                    :
                                    <AvatarBox student={studentForSendCoins} fullName={studentForSendCoins?.fullName} appendClass="" onClick={e => setModalOpen(true)}/>
                                }

                            </Grid>

                            <Grid item xs={8}  className="flex-col">
                                <Typography variant="body1" textAlign={"left"} color={theme.palette.text.secondary}>
                                    Нажми на аватарку, чтобы выбрать игрока которому хотите переслать coins.
                                </Typography>

                                <Box  className="field-input flex-row-vstart-hcenter" style={{border: '0.2rem solid #d4d4d4', marginTop: '2rem', marginBottom: '2rem'}}>
                                    <input onChange={e => setCoinsCount(e.target.value)} type={"number"} autoComplete={"false"}
                                           placeholder="Количество"/>
                                </Box>
                                <Button variant="contained" sx={{width: '100%', marginTop: 'auto', height: '10rem', borderRadius: '10rem', backgroundColor: theme.palette.info.dark}} onClick={e => {sendCoins()}}>
                                    <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.contrast}>
                                        Отправить
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                <Splitter/>
                <div className="content-wrappers">
                    <TitleRow title="Новости ЦБ"/>
                    <div>
                        <AvatarBox avatarUrl={CoinBankImage}/>
                        <Typography variant="h4" textAlign={"left"} marginTop={"4rem"} marginBottom={"4rem"} color={theme.palette.text.primary}>
                            Совет экспертов:
                        </Typography>

                        <Typography variant="body4" textAlign={"left"} color={theme.palette.text.secondary}>
                            Похоже все идет к тому что к аукциону, набранные Coin будут почти <strong>ничего не
                            стоить.</strong><br/><br/>
                            Ожидается дальнейшее <strong>снижение</strong> курса!<br/><br/>
                            Чем больше потратишь сегодня, тем больше заработаешь для аукциона. <strong>Нет смысла
                            тянуть.</strong>
                        </Typography>
                        <div className="hint-text small">

                        </div>
                    </div>
                </div>
                <Splitter/>
                <ModalWithContent content={<SelectTargetUser setTargetStudent={selectStudent}/>} isOpen={modalOpen} onClose={() => setModalOpen(false)}/>
                <QuestionCode code={code} questionCodeViewed={questionCodeViewed} setViewed={value => setQuestionCodeViewed(value)}/>
            </div>
        );
    }
}

let questionTimer;
function QuestionCode({code, questionCodeViewed, setViewed}) {
    const theme = useTheme();
    const [time, setTime] = useState(12);
    const [currentToken, setToken] = useContext(TokenContext);
    const [questionCode, setQuestionCode] = useState();
    const {enqueueSnackbar} = useSnackbar();
    const [answer, setAnswer] = useState();
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);

    function handleClose() {
        setViewed(false);
    }

    function setQuestionCodeAndStartTime(currentQuestionCode){
        setQuestionCode(currentQuestionCode);
    }

    useEffect(() => {
        if(questionCode && (questionCode.correct === null || questionCode.correct === "null")) {
            console.log("questionTimer");
            setTimeout(() => {
                setTime(parseInt(time) - 1)
            },1000)
        }
    }, [questionCode, time]);

    function activateCode() {
        console.log("activateCode");
        setPreloaderModalIsActive(true);
        AxiosPostWithoutState(apiUrl + "/questionCode/activate/" + code, null, null, currentToken).then(({
                                                                                              loaded,
                                                                                              error,
                                                                                              data
                                                                                          }) => {
            if (error) {
                enqueueSnackbar(error, {variant: 'error'});
                console.log("Произошла ошибка при сохранении: \n" + error);
            } else {
                setQuestionCodeAndStartTime(data);
            }
            setPreloaderModalIsActive(false);
        })
            .catch((error) => {
                enqueueSnackbar("Возникла непредвиденная ошибка, повторите позже", {variant: 'error'});
                setPreloaderModalIsActive(false);
            });
    }

    function sendAnswer() {
        setPreloaderModalIsActive(true);
        AxiosPostWithoutState(apiUrl + "/questionCode/sendAnswer/" + questionCode.value + "/" + answer, null, null, currentToken).then(({
                                                                                                             loaded,
                                                                                                             error,
                                                                                                             data
                                                                                                         }) => {
            if (error) {
                enqueueSnackbar(error, {variant: 'error'});
                console.log("Произошла ошибка при сохранении: \n" + error);
            } else {
                setQuestionCode(data);
            }
            setPreloaderModalIsActive(false);
        })
            .catch((error) => {
                enqueueSnackbar("Возникла непредвиденная ошибка, повторите позже", {variant: 'error'});
                setPreloaderModalIsActive(false);
            });
    }

    if(questionCodeViewed === true) {
        if(!questionCode)
            return (
                <Box className="absolute-position flex-col-vcenter-hcenter p-4" bgcolor={"#000000cf"} style={{zIndex: 100}}>
                    <Box className={"absolute-position"} top={0} zIndex={101} height={"fit-content"}>
                        <Box padding={"4rem"} className={"flex-row-vend"}>
                            <Box  onClick={e => handleClose()}>
                                <FontAwesomeIcon icon={faXmark} style={{color: "#f1f1f1", height: "6rem"}} />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h1" textAlign={"left"} color={theme.palette.text.contrast}>
                            Ты нашел qr код с вопросом.<br/><br/>
                            На ответ на вопрос у тебя будет всего 12 секунд и больше ответить на этот вопрос будет нельзя.<br/><br/>
                            Ты готов?
                        </Typography>

                        <Button  variant="contained" sx={{
                            width: '100%',
                            marginTop: '4rem',
                            height: '10rem',
                            borderRadius: '10rem',
                            backgroundColor: theme.palette.info.dark
                        }} onClick={e => {
                            activateCode()
                        }}>
                            <Typography variant="button" sx={{textWrap: "nowrap", padding: '1rem 2rem'}}
                                        color={theme.palette.text.contrast}>
                                Активировать
                            </Typography>
                        </Button>;
                    </Box>
                </Box>
            );
        else{
            console.log(questionCode);
            if(questionCode && (questionCode.correct === true || questionCode.correct === false)) {
                return (
                    <>
                        {questionCode && questionCode.correct === true
                            ?
                            <Box className="absolute-position flex-col-vcenter-hcenter p-4" bgcolor={"#000000cf"}
                                 style={{zIndex: 100}}>
                                <Box className={"absolute-position"} top={0} zIndex={101} height={"fit-content"}>
                                    <Box padding={"4rem"} className={"flex-row-vend"}>
                                        <Box onClick={e => handleClose()}>
                                            <FontAwesomeIcon icon={faXmark} style={{color: "#f1f1f1", height: "6rem"}}/>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography marginTop={"4rem"} marginBottom={"4rem"} variant="h1" textAlign={"left"}
                                                color={theme.palette.text.contrast}>
                                        Все верно! На твой счет зачислено {questionCode.cost} Sol Coins
                                    </Typography>
                                </Box>
                            </Box>
                            :
                            <></>
                        }

                        {questionCode && questionCode.correct === false
                            ?
                            <Box className="absolute-position flex-col-vcenter-hcenter p-4" bgcolor={"#000000cf"}
                                 style={{zIndex: 100}}>
                                <Box className={"absolute-position"} top={0} zIndex={101} height={"fit-content"}>
                                    <Box padding={"4rem"} className={"flex-row-vend"}>
                                        <Box onClick={e => handleClose()}>
                                            <FontAwesomeIcon icon={faXmark} style={{color: "#f1f1f1", height: "6rem"}}/>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography marginTop={"4rem"} marginBottom={"4rem"} variant="h1" textAlign={"left"}
                                                color={theme.palette.text.contrast}>
                                        Ответ неверный! Попробуй поискать другие qr коды чтобы ответить на новые вопросы
                                    </Typography>
                                </Box>
                            </Box>
                            :
                            <></>
                        }
                    </>
                )
            }
            else {
                return (
                    <Box className="absolute-position flex-col-vcenter-hcenter p-4" bgcolor={"#000000cf"} style={{zIndex: 100}}>
                        <Box className={"absolute-position"} top={0} zIndex={101} height={"fit-content"}>
                            <Box padding={"4rem"} className={"flex-row-vend"}>
                                <Box  onClick={e => handleClose()}>
                                    <FontAwesomeIcon icon={faXmark} style={{color: "#f1f1f1", height: "6rem"}} />
                                </Box>
                            </Box>
                        </Box>
                        <Box width={"100%"} position={"relative"}>
                            <Typography variant="hLarge" width={"100%"} display={"block"} textAlign={"center"} color={theme.palette.text.contrast}>
                                {time}
                            </Typography>
                            <Typography marginTop={"4rem"} width={"100%"} marginBottom={"4rem"} variant="h1" textAlign={"left"}
                                        color={theme.palette.text.contrast}>
                                {questionCode.question}
                            </Typography>

                            <Box className="field-input flex-row-vstart-hcenter" marginBottom={"4rem"}>
                                <input value={answer} onChange={e => setAnswer(e.target.value)}
                                       placeholder="Ответ" type={"text"} autoComplete="false"/>
                            </Box>

                            <Button variant="contained" sx={{
                                width: '100%',
                                marginTop: 'auto',
                                height: '10rem',
                                borderRadius: '10rem',
                                backgroundColor: theme.palette.info.dark
                            }} onClick={e => {
                                sendAnswer()
                            }}>
                                <Typography variant="button" sx={{textWrap: "nowrap", padding: '1rem 2rem'}}
                                            color={theme.palette.text.contrast}>
                                    Отправить
                                </Typography>
                            </Button>;
                        </Box>
                    </Box>
                );
            }
        }
    }
    else
        return <></>

}


export default Vault;
